/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
class Enumeration {
  constructor(obj) {
    Object.defineProperty(this, "mapping", {
      value: {},
      enumerable: false,
    });
    for (const key in obj) {
      this[key] = obj[key];
      this.mapping[this[key].value] = {
        name: key,
        text: this[key].text,
        fields: this[key],
      };
    }
    return Object.freeze(this);
  }

  getName(value) {
    const obj = this.mapping[value];
    return obj !== undefined ? obj.name : "";
  }

  getText(value) {
    const obj = this.mapping[value];
    return obj !== undefined ? obj.text : "";
  }

  getField(value, name) {
    const obj = this.mapping[value];
    return obj !== undefined ? obj.fields[name] : "";
  }

  has(key) {
    return Object.prototype.hasOwnProperty.call(this, key);
  }
}

const enums = {
  CountryDic: new Enumeration({
    China: {
      value: 1,
      name: "China",
      text: "中国",
    },
    USA: {
      value: 2,
      name: "USA",
      text: "America",
    },
    France: {
      value: 3,
      name: "France",
      text: "France",
    },
  }),
  LanguageDic: new Enumeration({
    cn: {
      value: 1,
      name: "cn",
      ElementLang: "zhCN",
      text: "中文",
    },
    en: {
      value: 2,
      name: "en",
      ElementLang: "en",
      text: "English",
    },
    it: {
      value: 3,
      name: "it",
      ElementLang: "it",
      text: "Italiano",
    },
    fr: {
      value: 4,
      name: "fr",
      ElementLang: "fr",
      text: "Français",
    },
    de: {
      value: 5,
      name: "de",
      ElementLang: "de",
      text: "Deutsch",
    },
    ko: {
      value: 6,
      name: "ko",
      ElementLang: "ko",
      text: "한국인",
    },
    ja: {
      value: 7,
      name: "ja",
      ElementLang: "ja",
      text: "日本語",
    },
    th: {
      value: 8,
      name: "th",
      ElementLang: "th",
      text: "ไทย",
    },
    pl: {
      value: 9,
      name: "pl",
      ElementLang: "pl",
      text: "Polski",
    },
    tr: {
      value: 10,
      name: "tr",
      ElementLang: "tr-TR",
      text: "Türk",
    },
    es: {
      value: 11,
      name: "es",
      ElementLang: "es",
      text: "Español",
    },
    ru: {
      value: 12,
      name: "ru",
      ElementLang: "ru-RU",
      text: "русский язык",
    },
    ua: {
      value: 13,
      name: "ua",
      ElementLang: "ua",
      text: "УкраїнськаName",
    },
    pt: {
      value: 14,
      name: "pt",
      ElementLang: "pt",
      text: "Portuguese",
    },
  }),
  LinkType: new Enumeration({
    Recommend: {
      value: "Recommend",
      name: "Recommend",
      text: "产品推荐",
    },
    Link: {
      value: "Link",
      name: "Link",
      text: "站外链接",
    },
    Function: {
      value: "Function",
      name: "Function",
      text: "功能页展示",
    },
    NoJump: {
      value: "NoJump",
      name: "NoJump",
      text: "无跳转",
    },
  }),
  ConditionPos: new Enumeration({
    A: {
      value: 1,
      name: "A",
      text: "液体管路A",
      Sov: "elineA.png",
      Txv: "elineA.png",
      TxvOld: "elineA.png",
      Eev: "elineA.png",
      Mv4: "Mv4Eline.png",
      Bv: "elineA.png",
      Cvs: "elineA.png",
      Sg: "elineA.png",
      Ssv: "elineA.png",
      Filter: "elineA.png",
      Xtf: "elineA.png",
      Eprv: "elineA.png",
    },
    C: {
      value: 1,
      name: "C",
      text: "吸气管路C",
      Sov: "elineC.png",
      Txv: "elineC.png",
      TxvOld: "elineC.png",
      Eev: "elineC.png",
      Mv4: "Mv4Eline.png",
      Bv: "elineC.png",
      Cvs: "elineC.png",
      Sg: "elineC.png",
      Ssv: "elineC.png",
      Filter: "elineC.png",
      Xtf: "elineC.png",
      Eprv: "elineC.png",
    },
    D: {
      value: 1,
      name: "D",
      text: "排气管路D",
      Sov: "elineD.png",
      Txv: "elineD.png",
      TxvOld: "elineD.png",
      Eev: "elineD.png",
      Mv4: "Mv4Eline.png",
      Bv: "elineD.png",
      Cvs: "elineD.png",
      Sg: "elineD.png",
      Ssv: "elineD.png",
      Filter: "elineD.png",
      Xtf: "elineD.png",
      Eprv: "elineD.png",
    },
  }),
  NormalType: new Enumeration({
    0: {
      value: 0,
      name: "NC",
      text: "常开",
    },
    1: {
      value: 1,
      name: "NO",
      text: "常开",
    },
  }),
  VariableSpeed: new Enumeration({
    1: {
      value: 1,
      name: "Fixed",
      text: "定频",
    },
    0: {
      value: 0,
      name: "Inverter",
      text: "变频",
    },
  }),
  Schrader: new Enumeration({
    "-1": {
      value: -1,
      name: "",
      text: "全部",
    },
    0: {
      value: 0,
      value2: "Yes",
      name: "Yes",
      text: "带充注接口",
    },
    1: {
      value: 1,
      value2: "NO",
      name: "NO",
      text: "不带充注接口",
    },
  }),
  EqualType: new Enumeration({
    "-1": {
      value: -1,
      name: "External",
      text: "外平衡",
    },
    1: {
      value: 1,
      name: "Internal",
      text: "内平衡",
    },
    0: {
      value: 0,
      name: "External",
      text: "外平衡",
    },
  }),
};

export default enums;
