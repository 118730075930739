module.exports = {

  title: 'Sanhua QuickFinder Online',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false,
  config: {
    id: 'sanhua', // 业务编号（对应业务配置编号）
    name: '三花选型', // 业务名称
    terminal: 'web', // 应用方式
    database: 'sanhua2' // 数据表
  }
}
