/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Vue from 'vue'
import App from './App.vue'
// Vue-Router
import router from './router'
// element-ui
import ElementUI from 'element-ui'
// import "@/assets/styles/element-variables.scss";
import '@/assets/theme/index.css'
import i18n from './lang'
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
})
// Vue.use(ElementUI);
// Vuex
import store from './store'

import moment from 'moment'
Vue.prototype.$moment = moment
const ImgUrl = process.env.VUE_APP_IMAGE_URL
Vue.prototype.Prefix = ImgUrl
// Vue.prototype.$echarts = echarts;

// global css
import '@/assets/styles/index.scss'
import '@/assets/styles/theme.scss'
// global iconfont
import '@/assets/iconfont/iconfont.css'

//util
import * as utils from '@/utils'
Vue.prototype.$utils = utils

import enums from '@/utils/enum'
const Global = {
  ...enums,
}
Vue.mixin({
  data() {
    return Global
  },
})

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app')
