import request from "@/utils/request";

export function getBaseValve(params) {
  return request({
    url: "/api/valve/baseinfo",
    method: "get",
    params,
  });
}

export function getValve(data) {
  return request({
    url: "/api/valve/seriallist",
    method: "post",
    data,
  });
}

export function getSerialField(params) {
  return request({
    url: "/api/valve/fetchserialfields",
    method: "get",
    params,
  });
}

export function getCondition(params) {
  return request({
    url: "/api/valve/selection",
    method: "get",
    params,
  });
}

export function getSerialList(params) {
  return request({
    url: "/api/valve/valveserials",
    method: "get",
    params,
  });
}

export function getSerialInfo(data) {
  return request({
    url: "/api/valve/info",
    method: "post",
    data,
  });
}

export function getSystemSerial(params) {
  return request({
    url: "/api/valve/sysvalvelist",
    method: "get",
    params,
  });
}

export function getRecommend(params) {
  return request({
    url: "/api/valve/getRecommend",
    method: "get",
    params,
  });
}

export function getWebConfig() {
  return request({
    url: "/api/valve/getWebConfig",
    method: "get",
  });
}
