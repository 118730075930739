import { getSystemSerial } from "@/api/valve";
import Cookies from "js-cookie";
import * as utils from "@/utils";
const Prefix = process.env.VUE_APP_IMAGE_URL;
const state = {
  systemSerial: {
    cn: {},
    en: {},
  },
};

const mutations = {
  SET_SYSTEM_SERIAL: (state, { language, systemId, result, serialList }) => {
    if (!state.systemSerial[language]) {
      state.systemSerial[language] = {};
    }
    if (!state.systemSerial[language][systemId]) {
      state.systemSerial[language][systemId] = { result: {}, list: [] };
    }
    state.systemSerial[language][systemId].result = utils.deepClone(result);
    state.systemSerial[language][systemId].list = serialList;
  },
};

const actions = {
  getSystemSerial({ commit, state, rootState }, { systemId }) {
    const language = rootState.app.language;
    return new Promise((resolve, reject) => {
      if (
        state.systemSerial[language] &&
        state.systemSerial[language][systemId]
      ) {
        resolve(state.systemSerial[language][systemId].list);
      } else {
        getSystemSerial({ systemId })
          .then((res) => {
            console.log('getSystemSerial',res);
            const result = res.Data.valveInfo;
            let serialList = [];
            for (let key in result) {
              serialList = serialList.concat(result[key]);
            }
            serialList.forEach((serial) => {
              const imageInfo = serial.ImgPath
                ? serial.ImgPath.split(";")
                : ["", ""];
              serial.imgUrl = Prefix + imageInfo[0];
            });
            commit("SET_SYSTEM_SERIAL", {
              language,
              systemId,
              result,
              serialList,
            });
            resolve(serialList);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
