export function UUID() {
  var s = []
  var hexDigits = '0123456789abcdef'
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'

  var uuid = s.join('')
  return uuid
}

export function _isMobile() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  return flag
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}
const getObj =
  typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol'
    ? function getType(val) {
        return typeof val
      }
    : function getSymbol(val) {
        return val && typeof Symbol === 'function' && val.constructor === Symbol && val !== Symbol.prototype
          ? 'symbol'
          : typeof val
      }

export function isString(val) {
  return typeof val === 'string' && val.constructor === String
}
export function isArray(val) {
  return (val === 0 ? 'undefined' : getObj(val)) === 'object' && val.constructor === Array
}
export function isObject(val) {
  console.log('isObject', val)
  return (val === 0 ? 'undefined' : getObj(val)) === 'object' && val.constructor !== Array
}
export function isNumber(val) {
  return typeof val === 'number'
}
/**
 * @param {numeber} currentY 需要移动的dom当前位置离网页顶端的距离
 * @param {number} targetY 需要移动的dom当前位置离要移到的位置的距离
 */
export function scroll(currentY, targetY) {
  // 计算需要移动的距离
  let needScrollTop = targetY - currentY
  let _currentY = currentY
  setTimeout(() => {
    // 一次调用滑动帧数，每次调用会不一样
    const dist = Math.ceil(needScrollTop / 10)
    _currentY += dist
    window.scrollTo(_currentY, currentY)
    // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
    if (needScrollTop > 10 || needScrollTop < -10) {
      scroll(_currentY, targetY)
    } else {
      window.scrollTo(_currentY, targetY)
    }
  }, 5)
}
/**
 *  isEmpty ?
 * @param {*} val
 * @returns {Boolean}
 */
export function isEmpty(val) {
  if (val !== undefined && val !== null && val !== 0) {
    // console.log('isEmpty', val)
    if (isNumber(val)) return false
    if (isString(val) && val !== '') return false
    if (isArray(val) && val.length !== 0) return false
    if (isObject(val) && JSON.stringify(val) !== '{}') return false
  }
  return true
}

/**
 * 判断两个对象是否相同
 * isObjectEqual ?
 * @param {Object} obj1
 * @param {Object} obj2
 * @returns {Boolean}
 */
export function isObjectEqual(obj1, obj2) {
  if (!obj1 || !obj2 || !isObject(obj1) || !isObject(obj2)) {
    new Error('参数必须是对象')
    return false
  }
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false
  }
  for (let key in obj1) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }
  return true
}

export function sleep(milliSeconds) {
  var startTime = new Date().getTime()
  while (new Date().getTime() < startTime + milliSeconds) {
    // console.log(new Date().getTime());
  } //暂停一段时间 10000=1S。
}

export function productSort(product1, product2) {
  if (product1.ProductType === 'Coil' && product2.ProductType === 'Product') {
    return 1
  } else if (product1.conditionIndex !== -1) {
    return 1
  } else {
    return -1
  }
}
