import md5 from "js-md5";
import { getlist, saveSelection } from "@/api/selection";
import { getSelection, setSelection } from "@/utils/selection";
import { buildConditionKey } from "@/utils/condition";

const state = {
  /** 选型列表 通过hashkey（md5） 分辨 选型是否唯一  （同一阀门，同一系列，同一子系列，相同型号，相同工况 保证唯一） */
  list: getSelection("Product") || [],
  /** 选型列表 hash 列表 */
  hashKeys: getSelection("Hashkey") || [],
  /** 是否同步过 用户选型列表 */
  getSaveState: false,
  /** 选型产品名称列表 */
  productKeyList: getSelection("ProductKey") || [],
};

const mutations = {
  SET_PRODUCT_LIST: (state, list) => {
    setSelection("Product", list);
    state.list = list;
  },
  SET_HASH_KEYS: (state) => {
    const list = state.list.map((i) => i.Hashkey);
    setSelection("Hashkey", list);
    state.hashKeys = list;
  },
  SET_GET_SAVE_STATE: (state, status) => {
    state.getSaveState = status;
  },
  SET_PRODUCT_KEY_LIST: (state) => {
    const list = state.list.map((i) => i.PartNumber);
    setSelection("ProductKey", list);
    state.productKeyList = list;
  },
};

const actions = {
  /** 添加选型产品 */
  saveModelAndProduct({ commit, state }, { condition = "", product }) {
    const { hashKeys, list } = state;
    const { Valveid, Serialid, Sizeid, PartNumber } = product;
    let newCondition;
    if (condition) {
      for (let key in condition) {
        const value = condition[key];
        const number = Number(value);
        condition[key] = isNaN(number) ? value : number;
      }
      newCondition = Object.keys(condition)
        .sort()
        .reduce((obj, i) => {
          obj[i] = condition[i];
          return obj;
        }, {});
    }

    const keyString = `${Valveid}-${Serialid}-${Sizeid}-${PartNumber}-${buildConditionKey(
      newCondition
    )}`;

    const Hashkey = md5(keyString).toUpperCase();
    console.log(keyString, Hashkey);
    if (!hashKeys.includes(Hashkey)) {
      list.push({ ...product, SelectCondition: condition, Hashkey });
      commit("SET_PRODUCT_LIST", list);
      commit("SET_HASH_KEYS");
      commit("SET_PRODUCT_KEY_LIST");
    }
  },
  /** 删除选型产品 */
  deleteProduct({ commit, state, dispatch }, index) {
    let { list } = state;
    list.splice(index, 1);
    commit("SET_PRODUCT_LIST", list);
    commit("SET_HASH_KEYS");
    commit("SET_PRODUCT_KEY_LIST");
    dispatch("saveUserSelection");
  },
  /** 修改同步状态 */
  changeGetSaveState({ commit }, status) {
    commit("SET_GET_SAVE_STATE", status);
  },
  /** 同步用户选型列表 */
  async getSaveSelect({ commit, dispatch }) {
    // const { list, hashKeys } = state;
    const resp = await getlist();
    if (resp.Result === 0 && resp.Data) {
      const { productList } = resp.Data;
      const list = productList.map((item) => {
        const keyString = `${item.Valveid}-${item.Serialid}-${item.Sizeid}-${item.PartNumber}`;
        item.PartNumber = item.ProductName;
        item.Sizename = item.SizeName;
        item.Hashkey = item.Hashkey || md5(keyString).toUpperCase();
        item.imageUrl = item.ImageUrl
          ? item.ImageUrl.indexOf("http") !== -1
            ? item.ImageUrl
            : item.ImageUrl.split(";")[0]
          : "";
        return item;
        // if (!hashKeys.includes(item.Hashkey)) {
        //   list.push(item);
        // }
      });
      commit("SET_PRODUCT_LIST", list);
      commit("SET_HASH_KEYS");
      commit("SET_PRODUCT_KEY_LIST");
      if (list.length > 0) dispatch("saveUserSelection");
    }
  },
  /** 保存用户选型列表 */
  async saveUserSelection({ rootState, state }) {
    const { token } = rootState.user;
    if (!token) return;
    const { list } = state;
    const conditionList = [];
    const productList = list.map((item, idx) => {
      const { SelectCondition } = item;
      conditionList.push(SelectCondition || null);
      item.conditionIndex = SelectCondition ? idx : -1;
      item.valveId = item.Valveid;
      item.serialId = item.Serialid;
      item.productName = item.PartNumber || item.ProductName;
      return item;
    });
    const resp = await saveSelection({ conditionList, productList });
    return resp;
  },
  /** 清空选型列表 */
  clearSelection({ commit, rootState, dispatch }, userClear = false) {
    const { token } = rootState.user;
    commit("SET_PRODUCT_LIST", []);
    commit("SET_HASH_KEYS");
    commit("SET_PRODUCT_KEY_LIST");
    if (token && userClear) {
      dispatch("saveUserSelection");
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
