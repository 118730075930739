import { converttoany_temper } from "@/api/UnitConvert";

const change = function (params, callback, errorback) {
  converttoany_temper(params)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      errorback(err);
    });
};

export default change;
