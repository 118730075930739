import { getSerialInfo } from "@/api/valve";
const state = {
  cn: {},
  en: {},
  it: {},
  list: [],
};

const mutations = {
  SET_PRODUCT_LIST: (
    state,
    { valveName, region, result, safetycer, language }
  ) => {
    if (!state[region]) {
      state[region] = {};
    }
    if (!state[region][language]) {
      state[region][language] = {};
    }
    if (!state[region][language][valveName]) {
      state[region][language][valveName] = {};
    }
    if (!state[region][language][valveName][safetycer]) {
      state[region][language][valveName][safetycer] = {};
    }
    state[region][language][valveName][safetycer] = result;
  },
  SET_LIST: (state, list) => {
    state.list = state.list.concat(list);
    // localStorage.setItem("searchData", JSON.stringify(state.list));
  },
};

const actions = {
  getProductList(
    { commit, rootState, state },
    {
      keyword = "",
      screen,
      valveName,
      Valveid,
      selection,
      productKeyList,
      SerialIdList,
      pageIndex,
      pageSize,
    }
  ) {
    const { language, region } = rootState.app;
    const { safetycerSelect } = rootState.condition;
    // console.log(keyword);
    const valve = rootState.valve.valveDic[valveName];
    const safetycerStr = safetycerSelect.toString();
    return new Promise((resolve, reject) => {
      if (
        state[region] &&
        state[region][language] &&
        state[region][language][valveName] &&
        state[region][language][valveName][safetycerStr] &&
        state[region][language][valveName][safetycerStr].serialInfoList.length >
          0
      ) {
        let fieldDic =
          state[region][language][valveName][safetycerStr].fieldDic;
        const serialList =
          state[region][language][valveName][safetycerStr].serialList;
        const fieldList =
          state[region][language][valveName][safetycerStr].serialInfoFields;
        if (SerialIdList.length > 0) {
          let list = state[region][language][valveName][
            safetycerStr
          ].serialInfoList.filter((product) => {
            if (
              product.PartNumber.indexOf(keyword) != -1 &&
              SerialIdList.includes(product.Serialid)
            ) {
              let flag = true;
              for (let key in fieldDic) {
                if (key === "Refrigerant") {
                  flag = screen[key]
                    ? product.RefrigerantList.includes(screen[key])
                    : true;
                } else {
                  flag = screen[key] ? product[key] === screen[key] : true;
                }
                if (!flag) {
                  return false;
                }
              }
              return true;
            } else {
              return false;
            }
          });
          console.log(list);
          if (selection === "added") {
            list = list.filter((product) =>
              productKeyList.includes(product.PartNumber)
            );
          } else if (selection === "noAdd") {
            list = list.filter(
              (product) => !productKeyList.includes(product.PartNumber)
            );
          }
          const total = list.length;
          for (let key in fieldDic) {
            fieldDic[key].list.forEach((value) => {
              value.disabled =
                key === "Refrigerant"
                  ? !list.some((item) =>
                      item.RefrigerantList.includes(value.label)
                    )
                  : !list.some((item) => item[key] === value.label);
            });
          }
          const pagelist = list.slice(
            (pageIndex - 1) * pageSize,
            pageIndex * pageSize
          );
          resolve({ serialList, fieldList, list: pagelist, total, fieldDic });
        } else {
          let list = state[region][language][valveName][
            safetycerStr
          ].serialInfoList.filter((product) => {
            if (product.PartNumber.indexOf(keyword) != -1) {
              let flag = true;
              for (let key in fieldDic) {
                if (key === "Refrigerant") {
                  flag = screen[key]
                    ? product.RefrigerantList.includes(screen[key])
                    : true;
                } else {
                  flag = screen[key] ? product[key] === screen[key] : true;
                }
                if (!flag) {
                  return false;
                }
              }
              return true;
            } else {
              return false;
            }
          });
          if (selection === "added") {
            list = list.filter((product) =>
              productKeyList.includes(product.PartNumber)
            );
          } else if (selection === "noAdd") {
            list = list.filter(
              (product) => !productKeyList.includes(product.PartNumber)
            );
          }
          console.log(list);
          for (let key in fieldDic) {
            fieldDic[key].list.forEach((value) => {
              value.disabled =
                key === "Refrigerant"
                  ? !list.some((item) =>
                      item.RefrigerantList.includes(value.label)
                    )
                  : !list.some((item) => item[key] === value.label);
            });
          }
          const total = list.length;
          const pagelist = list.slice(
            (pageIndex - 1) * pageSize,
            pageIndex * pageSize
          );
          resolve({ serialList, fieldList, list: pagelist, total, fieldDic });
        }
      } else {
        const dataJson = require("@/assets/json/data.json");
        const params = { Valveid, valveName, showList: true };
        if (dataJson.HasSafetycerField.includes(valveName)) {
          params.safetycer = safetycerStr;
        }
        getSerialInfo(params)
          .then((res) => {
            const {
              serialList = [],
              serialInfoList = [],
              serialInfoFields = [],
            } = res.Data;
            const removeFields = ["PartNumber"];
            const fieldList = serialInfoFields.filter(
              (field) =>
                field.title !== "" &&
                !removeFields.includes(field.name) &&
                field.isShow !== 0
            );
            let fieldDic = {};
            if (valve.CalculateType === 0) {
              fieldList.forEach((field) => {
                fieldDic[field.name] = { ...field, list: [] };
              });
              serialInfoList.forEach((info) => {
                for (let key in fieldDic) {
                  if (key === "Refrigerant") {
                    const RefrigerantList = info.Refrigerant.split(",");
                    info.RefrigerantList = RefrigerantList;
                    RefrigerantList.forEach((ref) => {
                      const value = fieldDic[key].list.findIndex(
                        (item) => item.label === ref
                      );
                      if (value == -1 && info[key] && ref) {
                        fieldDic[key].list.push({
                          label: ref,
                          value: ref,
                          disabled: false,
                          different: false,
                        });
                      }
                    });
                  } else {
                    const value = fieldDic[key].list.findIndex(
                      (item) => item.label === info[key]
                    );
                    if (value == -1 && info[key]) {
                      fieldDic[key].list.push({
                        label: info[key],
                        value: info[key],
                        disabled: false,
                        different: false,
                      });
                    }
                  }
                }
              });
            }
            serialInfoList.forEach((info) => {
              info.PartNumber =
                valveName === "TxvOld" ? info.ProductName : info.PartNumber;
              info.imageUrl = info.ImageUrl
                ? info.ImageUrl.indexOf("http") !== -1
                  ? info.ImageUrl
                  : info.ImageUrl.split(";")[0]
                : "";
            });

            /** ------------------- 处理搜索数据  ------------------------------------ */

            /*  const list = serialInfoList.map((info) => {
              const serial = serialList.find(
                (serialItem) => serialItem.Id == info.Serialid
              );
              let serialName = serial.Name;
              if (serialName.search(" ") !== -1) {
                serialName = serialName.replace(" ", "+");
              }
              let sizeName = info.Sizename || info.DataNo || "-";
              if (sizeName.search(" ") !== -1) {
                sizeName = sizeName.replace(" ", "+");
              }
              let partnumber = info.PartNumber;
              if (partnumber.search(" ") !== -1) {
                partnumber = partnumber.replace(" ", "+");
              }
              console.log(valve, serial, info.Serialid);
              const item = `${valve.Classname} ${valve.Name} ${serialName} ${sizeName} ${partnumber}`;

              return item;
            });
            commit("SET_LIST", list); */

            /** ------------------------------------------------------- */
            commit("SET_PRODUCT_LIST", {
              valveName,
              language,
              region,
              safetycer: safetycerStr,
              result: {
                serialList,
                serialInfoList,
                serialInfoFields: fieldList,
                fieldDic,
              },
            });
            if (SerialIdList.length > 0) {
              let list = serialInfoList.filter(
                (product) =>
                  product.PartNumber.indexOf(keyword) != -1 &&
                  SerialIdList.includes(product.Serialid)
              );
              const total = list.length;
              if (selection === "added") {
                list = list.filter((product) =>
                  productKeyList.includes(product.PartNumber)
                );
              } else if (selection === "noAdd") {
                list = list.filter(
                  (product) => !productKeyList.includes(product.PartNumber)
                );
              }
              const pagelist = list.slice(
                (pageIndex - 1) * pageSize,
                pageIndex * pageSize
              );
              resolve({ serialList, fieldList, list: pagelist, total });
            } else {
              let list = serialInfoList.filter(
                (product) => product.PartNumber.indexOf(keyword) != -1
              );
              const total = list.length;
              if (selection === "added") {
                list = list.filter((product) =>
                  productKeyList.includes(product.PartNumber)
                );
              } else if (selection === "noAdd") {
                list = list.filter(
                  (product) => !productKeyList.includes(product.PartNumber)
                );
              }
              const pagelist = list.slice(
                (pageIndex - 1) * pageSize,
                pageIndex * pageSize
              );
              resolve({
                serialList,
                fieldList,
                list: pagelist,
                total,
                fieldDic,
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },
  searchProduct({ commit }, keyword) {
    return new Promise((resolve) => {
      const list = JSON.parse(localStorage.getItem("searchData"));
      if (keyword === "") {
        resolve(list.slice(0, 10));
      } else {
        const keywordList = keyword.split(" ");
        console.log(keywordList);
        resolve(searchfilter(list, keywordList));
      }
    });
  },
};

function searchfilter(list, keyword) {
  let result = [];
  const keywordUp = keyword[0].toUpperCase();
  const reg = new RegExp(
    `(?<ValveName>${keywordUp})|(?<SerialName>[A-Za-z0-9.\\+\\-_()]*${keywordUp}[A-Za-z0-9.\\+\\-_()]*)`,
    "g"
  );
  let resultList = list.filter((item) => reg.test(item));
  if (keyword.length > 1) {
    const newKeyword = keyword.slice(1);
    result = searchfilter(resultList, newKeyword);
  } else {
    result = resultList;
  }
  return result;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
