<template>
  <div
    :class="
      ['home', 'login', 'register'].includes(route)
        ? 'login-header'
        : 'bg-img header'
    "
  >
    <div class="bg-img logo" @click="goHome"></div>
    <div class="search">
      <el-popover
        placement="bottom"
        width="600"
        trigger="manual"
        v-model="popoverShow"
      >
        <el-table
          size="mini"
          :show-header="false"
          :data="filterList"
          class="popoverTable"
          :max-height="300"
          :empty-text="
            keyword.length >= 2 && keyword.trim().length >= 2
              ? $t('message.noData')
              : $t('message.inputMore')
          "
          @row-click="rowClick"
        >
          <el-table-column prop="valve" width="150">
            <template slot-scope="{ row }">
              <span v-html="showData(row.valve)" />
            </template>
          </el-table-column>
          <el-table-column prop="serial" width="100">
            <template slot-scope="{ row }">
              <span v-html="showData(row.serial)" />
            </template>
          </el-table-column>
          <el-table-column prop="size" width="120">
            <template slot-scope="{ row }">
              <span v-html="showData(row.size)" />
            </template>
          </el-table-column>
          <el-table-column prop="partnumber">
            <template slot-scope="{ row }">
              <span v-html="showData(row.partnumber)" />
            </template>
          </el-table-column>
        </el-table>
        <el-input
          class="search-input"
          slot="reference"
          v-model="keyword"
          :placeholder="$t('home.searchPlace')"
          @input="searchInput"
          @blur="popoverShow = false"
          @focus="showPopover"
        >
          <i
            slot="prefix"
            class="el-input__icon el-icon-search"
            style="color: #ffffff"
          ></i
        ></el-input>
      </el-popover>
      <!-- <el-input
        class="search-input"
        v-model="keyword"
        :placeholder="$t('home.searchPlace')"
      >
        <i
          slot="prefix"
          class="el-input__icon el-icon-search"
          style="color: #ffffff"
        ></i
      ></el-input> -->
    </div>
    <div class="router" v-show="!['home', 'login', 'register'].includes(route)">
      <div class="route" @click="changeRoute('home')">
        {{ $t("home.home") }}
      </div>
      <div
        class="route"
        :class="route == 'Selector' ? 'isRoute' : ''"
        @click="changeRoute('Selector')"
      >
        {{ $t("home.selection") }}
      </div>
      <div
        class="route"
        :class="route == 'sort' ? 'isRoute' : ''"
        @click="changeRoute('sort')"
      >
        {{ $t("home.selectionList") }}
      </div>
      <div class="route" @click="unitGroup = true">
        {{ $t("home.unitChange") }}
      </div>
      <div class="route" @click="unitUtilsShow = true">
        {{ $t("home.unitUtils") }}
      </div>
    </div>
    <div class="menu">
      <el-dropdown v-if="user.token" @command="handleCommand">
        <span class="el-dropdown-link">
          {{ user.name }} <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="resetPassword">
            {{ $t("home.resetPassword") }}
          </el-dropdown-item>
          <el-dropdown-item command="changePhone">
            {{ $t("home.changePhone") }}
          </el-dropdown-item>
          <el-dropdown-item command="changeEmail">
            {{ $t("home.changeEmail") }}
          </el-dropdown-item>
          <el-dropdown-item divided command="exit">
            {{ $t("home.logout") }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <span v-else class="login" @click="goLogin">{{ $t("home.login") }}</span>
      <el-divider direction="vertical"></el-divider>
      <div class="Country" @click="selectCountry">
        <img
          v-if="nationalFlag"
          class="nationalFlag"
          :src="
            require('@/assets/images/nationalFlag/' + nationalFlag + '.png')
          "
        />
        <span class="name">{{ CountryName }}</span>
        <i class="el-icon-arrow-down icon"></i>
      </div>
    </div>
    <UnitGroup :unitGroup="unitGroup" @close="unitGroup = false"></UnitGroup>
    <unitUtils :show="unitUtilsShow" @close="unitUtilsShow = false" />
    <!-- <el-dialog
      :title="$t('home.unitUtils')"
      append-to-body
      :visible.sync="unitUtilsShow"
      width="415px"
      top="5vh"
    >
      <iframe
        class="unit-utils"
        id="unitUtils"
        width="375"
        height="677"
        style="border: 1px solid #666666"
        :src="`http://sanhuaselector.com/h5/#/pages/my/unitutilsIframe?language=${language}&iframe=true`"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="unitUtilsShow = false">取 消</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import UnitGroup from "./unitGroup.vue";
import Cookies from "js-cookie";
import { mapGetters, mapState } from "vuex";
import unitUtils from "./unitUtils.vue";
export default {
  name: "HeaderBar",
  components: { UnitGroup, unitUtils },
  data() {
    return {
      keyword: "",
      popoverShow: false,
      unitGroup: false,
      unitUtilsShow: false,
      filterList: [],
      CountryName: "",
    };
  },
  computed: {
    route: function () {
      return this.$route.name;
    },
    ...mapState({
      user: (state) => state.user,
      countryList: (state) => state.app.countryList,
      nationalFlag: (state) => state.app.countryNationalFlag,
    }),
    ...mapGetters({
      language: "language",
      country: "country",
    }),
  },
  watch: {
    country: {
      handler: function () {
        this.changeCountryName();
      },
    },
    language: {
      handler: function () {
        this.changeCountryName();
      },
    },
  },
  mounted() {
    this.lang = Cookies.get("language") || "cn";
    this.changeCountryName();
  },
  methods: {
    handleCommand(command) {
      console.log(command);
      switch (command) {
        case "exit":
          this.exit();
          break;
        case "resetPassword":
          this.$router.push({
            name: "reset",
            query: { redirect: this.$route.name },
          });
          break;
        case "changePhone":
          this.$router.push({
            name: "changePhone",
            query: { redirect: this.$route.name },
          });
          break;
        case "changeEmail":
          this.$router.push({
            name: "changeEmail",
            query: { redirect: this.$route.name },
          });
          break;

        default:
          break;
      }
    },
    changeCountryName() {
      const { language, country, countryList } = this;
      const CountryInfo = countryList.find((item) => item.Id === country);
      if (!CountryInfo) return;
      this.CountryName =
        language === "cn" ? CountryInfo.Country : CountryInfo.CountryEn;
    },
    changeRoute(name) {
      this.$router.push({ name: name });
    },
    goLogin() {
      const route = this.$route;
      console.log(route);
      const redirect =
        route.name === "login" ? this.$router.query.redirect : route.name;
      this.$router.push({ name: "login", query: { redirect } });
    },
    goHome() {
      this.$router.push({ name: "home" });
    },
    selectCountry() {
      this.$store.dispatch("app/setLangShow", true);
    },
    showPopover() {
      const { keyword } = this;
      if (keyword.length > 0) {
        this.popoverShow = true;
      }
    },
    searchInput(value) {
      if (value.length > 0) {
        this.popoverShow = true;
      }

      if (value.length >= 2 && value.trim().length >= 2) {
        this.$store.dispatch("productList/searchProduct", value).then((res) => {
          const list = res.map((item) => {
            const listItem = item.split(" ");
            let serial = listItem[2];
            if (serial.indexOf("+") !== -1) {
              serial = serial.replace("+", " ");
            }
            let size = listItem[3];
            if (size.indexOf("+") !== -1) {
              size = size.replace("+", " ");
            }
            let partnumber = listItem[4];
            if (partnumber.indexOf("+") !== -1) {
              partnumber = partnumber.replace("+", " ");
            }
            return {
              valveName: listItem[0],
              valve: listItem[1],
              serial,
              size,
              partnumber,
            };
          });
          this.filterList = list;
        });
      } else {
        this.filterList = [];
      }
    },
    exit() {
      this.$store
        .dispatch("user/logout")
        .then(() => {
          this.$store.dispatch("selection/clearSelection");
          this.$message({
            message: this.$t("message.loginOutSuccess"),
            type: "success",
          });
          // this.goHome()
        })
        .catch((err) => {
          this.$message({
            message: "Error:" + err,
            type: "error",
          });
        });
    },
    rowClick(row) {
      console.log("table row click", row);
      const params = {
        valveName: row.valveName,
        keyword: row.partnumber,
      };
      console.log("header", this.$route);

      this.$router.push({ name: "sort", query: params });

      this.popoverShow = false;
      this.keyword = "";
    },
    showData(val) {
      const { keyword } = this;
      const str = val.toString();
      if (keyword.indexOf(" ") !== -1) {
        const keylist = keyword.split(" ");
        let newStr = str;
        for (let key of keylist) {
          console.log(key);
          const upKeyword = key.toUpperCase();
          if (newStr.indexOf(key) !== -1) {
            newStr = newStr.replace(
              key,
              '<font color="red">' + key + "</font>"
            );
          } else if (newStr.indexOf(upKeyword) !== -1) {
            newStr = newStr.replace(
              upKeyword,
              '<font color="red">' + upKeyword + "</font>"
            );
          }
        }
        return newStr;
      } else {
        const upKeyword = keyword.toUpperCase();

        if (str.indexOf(keyword) !== -1 && keyword !== "") {
          return str.replace(
            keyword,
            '<font color="red">' + keyword + "</font>"
          );
        } else if (str.indexOf(upKeyword !== -1)) {
          return str.replace(
            upKeyword,
            '<font color="red">' + upKeyword + "</font>"
          );
        } else {
          return str;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-header {
  color: #ffffff;
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 20px;
  padding-top: 40px;

  .search {
    width: 400px;
    font-family: "PingFangSC-Semibold";
    margin-left: 20px;
  }
  .logo {
    cursor: pointer;
    width: 177px;
    flex-shrink: 0;
    height: 33px;
    background-image: url("../assets/images/logo-name.png");
  }
  .menu {
    margin-left: auto;
    display: flex;
    align-items: center;
    .login {
      cursor: pointer;
    }
    .el-dropdown-link {
      cursor: pointer;
      color: #ffffff;
    }
    .el-icon-arrow-down {
      font-size: 12px;
    }
  }
}
.header {
  width: 100%;
  height: 60px;
  display: flex;
  position: relative;
  background-image: url("../assets/images/bg.png");
  background-position: top;
  color: #ffffff;
  align-items: center;

  .search {
    width: 280px;
    font-family: "PingFangSC-Semibold";
    margin-left: 30px;
  }
  .logo {
    width: 177px;
    height: 33px;
    margin-left: 80px;
    flex-shrink: 0;
    cursor: pointer;
    background-image: url("../assets/images/logo-name.png");
  }
  .menu {
    margin-left: auto;
    margin-right: 55px;
    font-size: 14px;
    display: flex;
    align-items: center;
    .login {
      cursor: pointer;
    }
    .el-dropdown-link {
      cursor: pointer;
      color: #ffffff;
    }
  }
}
.icon {
  margin-left: 10px;
  cursor: pointer;
}
.Country {
  display: inline-flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
  .nationalFlag {
    width: 30px;
    height: auto;
  }
  .name {
    margin-left: 10px;
  }
}
.router {
  // position: absolute;
  // left: 50%;
  // transform: translateX(-50%);
  margin-left: 8%;
  display: flex;
  color: rgba($color: #ffffff, $alpha: 0.5);

  .route {
    cursor: pointer;
    font-size: 16px;
    & + .route {
      margin-left: 60px;
    }
    &.isRoute {
      color: #ffffff;
    }
  }
}
@media screen and (max-width: 1400px) {
  .header {
    .logo {
      width: 177px;
      height: 33px;
      margin-left: 20px;
      cursor: pointer;
      background-image: url("../assets/images/logo-name.png");
    }
  }
  .router {
    margin-left: 4%;
    .route + .route {
      margin-left: 30px;
    }
  }
}
</style>
<style lang="scss">
.login-header,
.header {
  .search-input {
    input {
      color: #ffffff;
    }
  }
  .el-input--prefix .el-input__inner {
    border: none;
    border-radius: 2px;
    background: rgba($color: #ffffff, $alpha: 0.1);
    caret-color: #ffffff;
  }
  .el-input__inner::-webkit-input-placeholder {
    color: #ffffff;
  }
  .el-input__inner:-moz-placeholder {
    color: #ffffff;
  }
  .el-input__inner::-moz-placeholder {
    color: #ffffff;
  }
  .el-input__inner::-ms-input-placeholder {
    color: #ffffff;
  }
  .el-table__cell {
    border-bottom: none;
  }
}
.popoverTable {
  &::before {
    height: 0px !important;
  }
}
</style>
