<template>
  <div class="app-main">
    <div class="header" ref="header">
      <Header ref="headerBar"></Header>
      <selectionlist></selectionlist>
    </div>
    <div class="RouteView" :style="{ 'margin-top': `${mth}px` }">
      <router-view></router-view>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/FooterBar.vue";
import Header from "@/components/header.vue";
import Selectionlist from "@/components/Selectionlist.vue";
export default {
  name: "LayoutIndex",
  components: { Footer, Header, Selectionlist },
  data() {
    return {
      mth: 60,
    };
  },
  computed: {
    isShrink: function () {
      return this.$store.state.app.SelectionShrink;
    },
    hiddenSelection: function () {
      return this.$store.state.app.hiddenSelection;
    },
  },
  watch: {
    hiddenSelection: function () {
      this.buildMTH();
    },
    isShrink: function () {
      this.buildMTH();
    },
  },
  mounted() {
    this.buildMTH();
  },
  methods: {
    buildMTH() {
      this.$nextTick(() => {
        var header = this.$refs.header;
        var headerbar = this.$refs.headerBar;
        this.mth =
          header.offsetHeight == 0
            ? headerbar.$el.offsetHeight
            : header.offsetHeight;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  .header {
    width: 100%;
    position: fixed;
    z-index: 999;
    top: 0;
  }

  .RouteView {
    min-height: calc(100vh - 80px);
    background-color: #f0f0f0;
  }
}
</style>
