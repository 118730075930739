<template>
  <el-dialog
    :title="$t('form.dialogTitle')"
    :show-close="false"
    :visible.sync="langShow"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="30%"
  >
    <div class="language-country">
      <div v-if="noCountry">
        <p style="margin-top: 0">未查询到您的国家信息，请选择:</p>
        <p style="margin-top: 0">
          Your country information is not found, please select:
        </p>
      </div>
      <div class="tips">Tips:{{ $t("langSelect.tips") }}</div>
      <el-form :model="formData" label-position="left" label-width="auto">
        <el-form-item prop="language" :label="$t('langSelect.language')">
          <el-select filterable v-model="formData.language" style="width: 100%">
            <el-option
              v-for="option in LanguageDic"
              :key="option.name"
              :label="option.text"
              :value="option.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="country" :label="$t('langSelect.country')">
          <el-select filterable v-model="formData.country" style="width: 100%">
            <el-option
              v-for="option in countryList"
              :key="option.Id"
              :label="option.label"
              :value="option.Id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <el-button @click="cancel">{{ $t("form.cancel") }}</el-button>
      <el-button type="primary" @click="settingLang">{{
        $t("form.confirm")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Cookies from "js-cookie";
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      formData: {
        language: "",
        country: "",
      },
    };
  },
  computed: {
    ...mapGetters(["langShow", "language", "country"]),
    ...mapState({
      countryList: (state) => state.app.countryList,
      countryMap: (state) => state.app.countryMap,
      noCountry: (state) => state.app.noCountry,
    }),
  },
  watch: {
    language: {
      immediate: true,
      handler: function (nVal) {
        this.formData.language = nVal;
      },
    },
    country: {
      immediate: true,
      handler: function (nVal) {
        this.formData.country = nVal;
      },
    },
  },
  methods: {
    cancel() {
      const { language, country } = this;
      Cookies.set("language", language);
      Cookies.set("country", country);
      this.$store.dispatch("app/setLangShow", false);
    },
    async settingLang() {
      const { language, country } = this.formData;
      const { countryMap } = this;
      Cookies.set("language", language);
      Cookies.set("country", country);
      const CountryInfo = countryMap[country];

      if (country !== this.country) {
        this.$store.dispatch("app/setCountry", country);
        this.$store.dispatch("recommend/getRecommend");
      }
      this.$store.dispatch(
        "app/setCountryNationalFlag",
        CountryInfo.NationalFlag
      );
      this.$store.dispatch("app/setLangShow", false);
      this.$store.dispatch("app/setLanguage", language);
      await this.$store.dispatch("valve/getValveBaseInfo");
      this.$store.dispatch("app/setNoCountry", false);
      this.$message({
        message: "Switch Success",
        type: "success",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tips {
  margin-bottom: 20px;
}
</style>
