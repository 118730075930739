import { getRecommend } from "@/api/valve";
import enums from "@/utils/enum";
import * as utils from "@/utils";
const ImgUrl = process.env.VUE_APP_IMAGE_URL;

// function getNoShow() {
//   const show = localStorage.getItem("noShowAgain");
//   return show === "true";
// }

const state = {
  show: false,
  recommendDic: {},
  recommendList: [],
};

const mutations = {
  SET_SHOW: (state, show) => {
    state.show = show;
  },
  SET_NO_SHOW_AGAIN: (state, show) => {
    state.noShowAgain = show;
  },
  SET_RECOMMEND_LIST: (state, list) => {
    state.recommendList = list;
  },
};

const actions = {
  getRecommend({ commit, rootState }) {
    return new Promise((resolve) => {
      getRecommend().then((res) => {
        const { LinkType } = enums;
        // console.log("Recommend list", res, LinkType);
        const ValveDic = rootState.valve.valveDic;
        const { productList, recommendList } = res.Data;
        recommendList.forEach((recommend) => {
          if (recommend.LinkType !== LinkType.Link.value) {
            const { Valveid, Serialid, Sizeid, PartItemid } = recommend;
            const valve = ValveDic[Valveid];
            recommend.ValveName = valve.Name;
            let productInfo = productList.find((productItem) => {
              const { product } = productItem;
              return (
                product.Valveid === Valveid &&
                product.Serialid === Serialid &&
                product.Sizeid === Sizeid &&
                product.Itemid === PartItemid
              );
            });
            if (productInfo) {
              const { product, infoFields } = productInfo;
              let fields = infoFields.filter(
                (field) => field.show !== 0 && !utils.isEmpty(field.title)
              );
              const imgUrl = product.imgUrl
                ? product.imgUrl.split(";")
                : ["", ""];
              product.imgUrl = ImgUrl + imgUrl[0];
              recommend.product = product;
              recommend.fields = fields;
            }
          }
          const imgUrl = recommend.ImgUrl
            ? recommend.ImgUrl.split(";")
            : ["", ""];
          recommend.imgUrl = ImgUrl + imgUrl[0];
          const key = `RECOMMENDNOSHOWAGAIN${recommend.Id}`;
          recommend.noShow =
            localStorage.getItem(key) === "true" ? true : false;
        });
        commit("SET_RECOMMEND_LIST", recommendList);
        if (recommendList.some((recommend) => !recommend.noShow)) {
          commit("SET_SHOW", true);
        } else {
          commit("SET_SHOW", false);
        }
        resolve();
      });
    });
  },
  setShow({ commit }, show) {
    commit("SET_SHOW", show);
  },
  setNoShowAgain({ commit }, recommend) {
    const key = `RECOMMENDNOSHOWAGAIN${recommend.Id}`;
    localStorage.setItem(key, recommend.noShow);
    commit("SET_NO_SHOW_AGAIN", recommend);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
