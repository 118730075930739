<template>
  <div class="footer">
    <!-- <span>© 2021 SANHUA Copyright</span> -->
    <a
      href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33062402000765"
      target="_blank"
      ><img width="20px" height="20px" src="@/assets/icons/gwab.png" />{{
        $t('home.gawRecordNo')
      }}</a
    >
    <a href="https://beian.miit.gov.cn" target="_blank">{{
      $t('home.recordNo')
    }}</a>
  </div>
</template>

<script>
export default {
  name: 'FooterBar'
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 60px;
  background-color: #001821;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  user-select: none;

  a + a {
    margin-left: 20px;
  }
  a {
    color: #ffffff;
    text-decoration: none;
  }
}
</style>