import request from '@/utils/request'

// 温度转换为℃
export function converttoc_temper(data) {
  return request({
    url: '/api/unitconvert/converttoc_temper',
    method: 'post',
    data,
  })
}

// 温度单位转换
export function converttoany_temper(data) {
  return request({
    url: '/api/unitconvert/converttoany_temper',
    method: 'post',
    data,
  })
}
// 温度单位转换 C=K=F/1.8
export function converttoshsc_temper(data) {
  return request({
    url: '/api/unitconvert/converttoshsc_temper',
    method: 'post',
    data,
  })
}
// 温度转换压力 convertto_temper_bar
export function convertto_temper_bar(params){
  return request({
    url:'/api/unitconvert/convertto_temper_bar',
    method:'post',
    params
  })
}

// 温度转换，返回K
export function converttosi_k_temper(data) {
  return request({
    url: '/api/unitconvert/converttosi_k_temper',
    method: 'post',
    data,
  })
}

/**
 * 压力转换
 * Pa 帕 kPa 千帕
 * 1千帊 = 1000帕 Bar 巴
 * 1巴 = 100000帕 mBar 毫巴
 * 1豪巴 = 100帕 atm 标准大气压
 * 1标准大气压 = 101325帕 mmHg 毫米汞柱
 * 1毫米汞柱 = 133.32帕 inHg 英寸汞柱
 * 1英寸汞柱 = 3386帕 Psi 磅力/平方英寸
 * 1磅力/平方英寸 = 6895帕 Psf 磅力/平方英尺
 * 1磅力/平方英尺 = 48.58帕
 */
export function converttoany_press(data) {
  return request({
    url: '/api/unitconvert/converttoany_press',
    method: 'post',
    data,
  })
}
// 压力 返回Pa
export function converttosi_pa_press(data) {
  return request({
    url: '/api/unitconvert/converttosi_pa_press',
    method: 'post',
    data,
  })
}

/**
 * 能量转换 // W 瓦 kW 千瓦
 * 1千瓦 = 1000瓦 BTU/h 英制热量单位/小时
 * 1英制热量单位/小时 = 0.293瓦 kBTU/h 千英制热量单位/小时
 * 1千英制热量单位/小时 = 293瓦 BTU/s 英制热量单位/秒
 * 1英制热量单位/秒 = 1055.06瓦 TR 冷吨
 * 1冷吨 = 3517瓦//3516.7 kcal/h 千卡/小时
 * 1千卡/小时 = 1.163瓦
 */
export function converttoany_capacity(data) {
  return request({
    url: '/api/unitconvert/converttoany_capacity',
    method: 'post',
    data,
  })
}

//能量返回 W
export function converttosi_w_capacity(data) {
  return request({
    url: '/api/unitconvert/converttosi_w_capacity',
    method: 'post',
    data,
  })
}

/**
 *质量流量转换
  kg/s 公斤/秒 
  kg/h 公斤/小时 
  1公斤/小时 = 0.00028公斤/秒 
  lb/h 磅/小时 
  1磅/小时 = 0.00013公斤/秒 
  lb/min 磅/分钟 
  1磅/分钟 = 0.0075公斤/秒
 */
export function converttoany_massflowrate(data) {
  return request({
    url: '/api/unitconvert/converttoany_massflowrate',
    method: 'post',
    data,
  })
}

/**
 *
 * 体积流量
 * //流量系数 m³/s 立方米/秒
 * m³/h 立方米/小时
 * 1立方米/小时 = 0.00028立方米/秒 in³/min 立方英寸/分钟
 * 1立方英寸/分钟 = 0.00000027立方米/秒 gal/min 美制加仑/分钟
 * 1美制加仑/分钟 = 0.000063立方米/秒
 */
export function converttoany_volumeflow(data) {
  return request({
    url: '/api/unitconvert/converttoany_volumeflow',
    method: 'post',
    data,
  })
}
//体积流量 //流量系数 返回 m³/s
export function converttosi_m3_s_volumeflow(data) {
  return request({
    url: '/api/unitconvert/converttosi_m3_s_volumeflow',
    method: 'post',
    data,
  })
}

//长度 直径 m 米 mm 毫米 1毫米 = 0.001米 Inch 英寸 1英寸 = 0.0254米
export function converttoany_length(data) {
  return request({
    url: '/api/unitconvert/converttoany_length',
    method: 'post',
    data,
  })
}

//长度 直径 返回mm 
export function converttosi_mm_length(data) {
  return request({
    url: '/api/unitconvert/converttosi_mm_length',
    method: 'post',
    data,
  })
}

//速度 m/s 米/秒 ft/s 英尺/秒 1英尺/秒 = 0.3048米/秒
export function converttoany_velocity(data) {
  return request({
    url: '/api/unitconvert/converttoany_velocity',
    method: 'post',
    data,
  })
}

//速度 返回m/s
export function converttosi_m_s_velocity(data) {
  return request({
    url: '/api/unitconvert/converttosi_m_s_velocity',
    method: 'post',
    data,
  })
}

/**
 * 密度
 * kg/m3 千克/立方米
 * lb/in3 磅/立方英寸
 * 1磅/立方英寸 = 27439千克/立方米
 * lb/ft3 磅/立方英尺
 * 1磅/立方英尺 = 16.07千克/立方米
 * lb/gal 磅/美制加仑
 * 1磅/美制加仑 = 118.42千克/立方米
 */
export function converttoany_density(data) {
  return request({
    url: '/api/unitconvert/converttoany_density',
    method: 'post',
    data,
  })
}

//密度 返回kg/m
export function converttosi_kg_m3_density(data) {
  return request({
    url: '/api/unitconvert/converttosi_kg_m3_density',
    method: 'post',
    data,
  })
}
//Enthalpy 焓 J/kg, kJ/kg, BTU/lb kJ/kg = 1000 J/kg 1BTU/lb = 2344.58 J/kg
export function converttoany_enthalpy(data) {
  return request({
    url: '/api/unitconvert/converttoany_enthalpy',
    method: 'post',
    data,
  })
}

//Enthalpy 焓 返回kj/kg
export function converttosi_kj_kg_enthalpy(data) {
  return request({
    url: '/api/unitconvert/converttosi_kj_kg_enthalpy',
    method: 'post',
    data,
  })
}

//Enthalpy 焓（直接返回 j/kg ）
export function converttoj_kg_enthalpy(data) {
  return request({
    url: '/api/unitconvert/converttoj_kg_enthalpy',
    method: 'post',
    data,
  })
}

/**
 * 比容
 * m3/kg 立方米/千克
 * 1立方米/千克 = 27738立方英寸/磅
 * in3/lb 立方英寸/磅
 * ft3/lb 立方英尺/磅
 * 1立方英尺/磅 = 1728立方英寸/磅
 * gal/lb 美制加仑/磅
 * 1美制加仑/磅 = 231立方英寸/磅
 */
export function converttoany_specificvolume(data) {
  return request({
    url: '/api/unitconvert/converttoany_specificvolume',
    method: 'post',
    data,
  })
}

/**
 比容 
 直接返回 in³/lb 
 m3/kg 立方米/千克 
 1立方米/千克 = 27738立方英寸/磅 
 in3/lb 立方英寸/磅 ft3/lb 立方英尺/磅 
 1立方英尺/磅 = 1728立方英寸/磅 
 gal/lb 美制加仑/磅 
 1美制加仑/磅 = 231立方英寸/磅
 */
export function converttoin3_lb_specificvolume(data) {
  return request({
    url: '/api/unitconvert/converttoin3_lb_specificvolume',
    method: 'post',
    data,
  })
}

//比容 返回 m³/kg
export function converttosi_m3_kg_specificvolume(data) {
  return request({
    url: '/api/unitconvert/converttosi_m3_kg_specificvolume',
    method: 'post',
    data,
  })
}

/**
 * 比熵
 * J/(kg.k) 焦/千克开氏度
 * kJ/(kg.k) 千焦/千克开氏度
 * 1千焦/千克开式度 = 1000焦/千克开式度
 * BTU/(lb.F) 英制热量单位/磅华氏度
 * 1英制热量单位/磅华氏度 = 5.13 焦/千克开式度
 */
export function converttoany_entropy(data) {
  return request({
    url: '/api/unitconvert/converttoany_entropy',
    method: 'post',
    data,
  })
}

//  比熵 直接返回 J/(kg.k)
export function converttoj_kg_k_entropy(data) {
  return request({
    url: '/api/unitconvert/converttoj_kg_k_entropy',
    method: 'post',
    data,
  })
}

//  比熵 直接返回 kj/kg.k
export function converttosi_kj_kg_k_entropy(data) {
  return request({
    url: '/api/unitconvert/converttosi_kj_kg_k_entropy',
    method: 'post',
    data,
  })
}


// 温度转换压力
export function convertTempToPress(data) {
  return request({
    url: "/api/unitconvert/converttemptopress",
    method: "post",
    data,
  });
}

// 压力转换温度
export function convertPressToTemp(data) {
  return request({
    url: "/api/unitconvert/convertpresstotemp",
    method: "post",
    data,
  });
}
