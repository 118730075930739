/*
 * @Author: Ljh <1483613982@qq.com>
 * @Date: 2022-06-22 00:26:47
 * @LastEditTime: 2022-07-19 13:34:58
 * @FilePath: \sanhua-selector\src\store\getters.js
 * @Description:
 */
const getters = {
  name: (state) => state.user.name,
  token: (state) => state.user.token,
  langShow: (state) => state.app.langShow,
  country: (state) => state.app.country,
  region: (state) => state.app.region,
  language: (state) => state.app.language,
  unitId: (state) => state.app.Unitid,
  valveBaseInfo: (state) => state.valve.valveBaseInfo,
  valveDic: (state) => state.valve.valveDic,
  productKeyList: (state) => state.selection.productKeyList,
  refrigerantList: (state) => state.valve.refrigerantList,
  getSaveState: (state) => state.selection.getSaveState,
  Refrigerant: (state) => state.condition.Refrigerant,
  safetycerSelect: (state) => state.condition.safetycerSelect,
  valveSystem: (state) => state.valve.valveSystem,
  valveSystemDic: (state) => state.valve.valveSystemDic,
  valveSystemSort: (state) => state.valve.valveSystemSort,
  webConfig: (state) => state.app.webConfigDic,
}
export default getters
