import request from "@/utils/request";
import { config } from "@/settings";

export function register(data) {
  return request({
    url: "/api/authorize/register",
    method: "post",
    data,
  });
}

export function login(data) {
  const params = {
    account: data.username,
    password: data.password,
    BusinessId: config.id,
    BusinessName: config.name,
    Terminal: config.terminal,
  };
  return request({
    url: "/api/authorize/login",
    method: "post",
    data: params,
  });
}

export function getInfo() {
  return request({
    url: "/api/authorize/validatetoken",
    method: "post",
  });
}

export function refresh(data) {
  const params = {
    account: data.username,
    password: data.password,
    BusinessId: config.id,
    BusinessName: config.name,
    Terminal: config.terminal,
  };
  return request({
    url: "/api/authorize/refresh",
    method: "post",
    data: params,
  });
}

// /api/authorize/getloginauth
export function getLoginAuth() {
  return request({
    url: "/api/authorize/getloginauth",
    method: "get",
  });
}

export function forbidden(data) {
  const params = {
    account: data.username,
    password: data.password,
    BusinessId: config.id,
    BusinessName: config.name,
    Terminal: config.terminal,
  };
  return request({
    url: "/api/authorize/forbidden",
    method: "post",
    data: params,
  });
}

// /api/valve/saveselectionsetting
export function saveUserSetting(data) {
  return request({
    url: "/api/valve/saveselectionsetting",
    method: "post",
    data,
  });
}

// /api/valve/savepersonunit
export function saveUserCustomUnit(data) {
  return request({
    url: "/api/valve/savepersonunit",
    method: "post",
    data,
  });
}

// /api/valve/deletepersonunitbyid
export function deleteUserCustomUnit(data) {
  return request({
    url: "/api/valve/deletepersonunitbyid",
    method: "post",
    data,
  });
}

// /api/valve/getpersonunitlist
export function getUserCustomUnit(data) {
  return request({
    url: "/api/valve/getpersonunitlist",
    method: "post",
    data,
  });
}

export function resetAccount(data, params) {
  return request({
    url: "/api/authorize/resetaccount",
    method: "post",
    params,
    data,
  });
}

/**
 * 发送邮箱验证码
 */
export function sendEmailCode(data) {
  return request({
    url: "/api/sms/sendemail",
    method: "post",
    data,
  });
}

/**
 * 短信验证码
 * @param {*} data
 * @returns
 */
export function sendSMSCode(data) {
  return request({
    url: "/api/sms/sendaliyunsms",
    method: "post",
    data,
  });
}

export function ValidAuthCode(data) {
  return request({
    url: "/api/authorize/validauthcode",
    method: "post",
    data,
  });
}
