<template>
  <el-dialog
    :title="$t('unitGroup.title')"
    :visible.sync="dialogShow"
    append-to-body
    top="5vh"
    width="25%"
  >
    <div class="unitGroup">
      <el-form label-width="auto">
        <el-form-item :label="$t('unitGroup.baseUnit')">
          <el-select v-model="UnitId">
            <el-option value="1" :label="$t('unitGroup.unitid1')"></el-option>
            <el-option value="2" :label="$t('unitGroup.unitid2')"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('unitGroup.customUnit')">
          <el-select v-model="customUnitId" clearable>
            <el-option
              v-for="unit in customUnitGroupList"
              :key="unit.id"
              :label="unit.unitName"
              :value="unit.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <h4>{{ $t("unitGroup.newUnitSystem") }}</h4>
      <div class="form">
        <el-form
          ref="unitForm"
          :model="newUnit"
          label-width="auto"
          :rules="rules"
        >
          <el-form-item :label="$t('unitGroup.unitName')" prop="unitName">
            <el-input v-model="newUnit.unitName"></el-input>
          </el-form-item>
          <el-form-item
            v-for="field in fields"
            :key="field.name"
            :label="$t(`unitGroup.${field.name}`)"
            :prop="field.name"
          >
            <el-select v-model="newUnit[field.name]">
              <el-option
                v-for="option in optionMap[field.option]"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="addNewUnit">
              {{ this.$t("unitGroup.save") }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogShow = false">
        {{ this.$t("form.cancel") }}
      </el-button>
      <el-button type="primary" @click="unitGroupChange">
        {{ this.$t("form.confirm") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
/**
 * @param {Boolean} unitGroup dialog显示状态
 * @param {String} UnitId 单位制Id
 * @param {Object} newUnit 新增单位制
 * @param {Object} optionMap 单位列表合集
 */
import Cookies from "js-cookie";
import { mapState } from "vuex";
export default {
  name: "unitGroup",
  props: {
    unitGroup: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    var validateUnitName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("message.unitName")));
      } else {
        callback();
      }
    };
    return {
      dialogShow: false,
      UnitId: 2,
      customUnitId: "",
      newUnit: {
        unitName: "",
        Temp: "",
        Temp2: "",
        Press: "",
        CoolCapacity: "",
        HeatCapacity: "",
        Velocity: "",
        VolumeFlow: "",
        MassFlow: "",
        Size: "",
        Density: "",
        Enthalpy: "",
        Entropy: "",
        FlowFactor: "",
      },
      optionMap: {
        TempUnits: [],
        PressureUnits: [],
        PowerUnits: [],
        FlowUnits: [],
        VelocityUnits: [],
        VolumeUnits: [],
        SizeUnits: [],
        DensityUnits: [],
        EnthalpyUnits: [],
        EntropyUnits: [],
        FlowFactorUnits: [],
      },
      defaultUnit: {
        1: null,
        2: null,
      },
      fields: [
        { name: "Temp", option: "TempUnits" },
        { name: "Temp2", option: "TempUnits" },
        { name: "Press", option: "PressureUnits" },
        { name: "CoolCapacity", option: "PowerUnits" },
        { name: "HeatCapacity", option: "PowerUnits" },
        { name: "Velocity", option: "VelocityUnits" },
        { name: "VolumeFlow", option: "VolumeUnits" },
        { name: "MassFlow", option: "FlowUnits" },
        { name: "Size", option: "SizeUnits" },
        { name: "Density", option: "DensityUnits" },
        { name: "Enthalpy", option: "EnthalpyUnits" },
        { name: "Entropy", option: "EntropyUnits" },
        { name: "FlowFactor", option: "FlowFactorUnits" },
      ],
      rules: {
        unitName: [
          {
            required: true,
            validator: validateUnitName,
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      customUnitGroupList: (state) => state.app.customUnitGroupList,
      stateCustomUnitId: (state) => state.app.customUnitId,
    }),
  },
  watch: {
    unitGroup: {
      immediate: true,
      handler: function (nVal) {
        this.dialogShow = nVal;
        if (nVal) {
          // 获取单位制
          this.UnitId = Cookies.get("UnitId") || "2";
          Object.assign(this.newUnit, this.defaultUnit[this.UnitId]);
        }
      },
    },
    stateCustomUnitId: {
      immediate: true,
      handler: function (nVal) {
        this.customUnitId = nVal;
      },
    },
    dialogShow: {
      handler: function (nVal) {
        if (nVal === false) {
          this.$emit("close", false);
        }
      },
    },
    UnitId: {
      handler: function () {
        Object.assign(this.newUnit, this.defaultUnit[this.UnitId]);
      },
    },
  },
  created() {
    const dataJson = require("@/assets/json/data.json");
    for (let key in this.optionMap) {
      this.optionMap[key] = dataJson[key];
    }
    const { defaultUnit } = dataJson;
    Object.assign(this.defaultUnit, defaultUnit);
  },

  methods: {
    // 修改单位制
    unitGroupChange() {
      const { UnitId, customUnitId } = this;
      this.$store.dispatch("app/setUnitId", UnitId);

      this.$store.dispatch("app/setCustomUnitId", customUnitId);

      this.dialogShow = false;
    },
    // 保存新增单位
    addNewUnit() {
      this.$refs.unitForm.validate((valid) => {
        if (valid) {
          const { newUnit } = this;
          const unitGroup = this.$utils.deepClone(newUnit);
          const keys = Object.keys(unitGroup);
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (key !== "unitName") {
              const value = unitGroup[key];
              if (!this.$utils.isEmpty(value)) {
                this.$store.dispatch("app/addUnitGroup", unitGroup);
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                for (let key in this.newUnit) {
                  this.newUnit[key] = null;
                }
                return;
              }
            }
            if (i === keys.length - 1) {
              this.$message({
                message: this.$t("message.unitAtLeastOne"),
                type: "error",
              });
              return false;
            }
          }
        } else {
          this.$message({
            message: this.$t("message.unitName"),
            type: "error",
          });
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  // height: calc(100vh - 400px);
  // overflow: auto;
}
</style>
