/* eslint-disable no-undef */
import Vue from "vue";
import VueI18n from "vue-i18n";
import enums from "@/utils/enum";
import Cookies from "js-cookie";
import enLocale from "./en";
import zhLocale from "./zh";
let elementEnLocale = ELEMENT.lang.en;
let elementZhLocale = ELEMENT.lang.zhCN;
Vue.use(VueI18n);

export const loadedLanguages = ["cn", "en"];

let messages = {
  cn: {
    ...zhLocale,
    ...elementZhLocale,
  },
  en: {
    ...enLocale,
    ...elementEnLocale,
  },
};

const i18n = new VueI18n({
  locale: getLanguage(),
  fallbackLocale: "en",
  messages,
});

export function getLanguage() {
  const chooseLanguage = Cookies.get("language");
  if (chooseLanguage) return chooseLanguage;

  const navigatorLang = navigator.language || navigator.browserLanguage;
  let language = navigatorLang.toLowerCase();
  if (language.indexOf("zh-") !== -1) {
    language = "cn";
  }
  if (language.indexOf("en-") !== -1) {
    language = "en";
  }
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return "en";
}
function setI18nLanguage(lang) {
  i18n.locale = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export function loadLanguageAsync(lang) {
  if (loadedLanguages.includes(lang)) {
    i18n.locale = lang;
    return Promise.resolve(setI18nLanguage(lang));
  }
  return import(
    /* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.js`
  ).then((langMessages) => {
    const { LanguageDic } = enums;
    const elementLangKey = LanguageDic[lang].ElementLang || "en";
    loadLangCDN(elementLangKey, (res) => {
      let elementLang;
      if (res === "success") {
        elementLang = ELEMENT.lang[elementLangKey];
      } else {
        elementLang = ELEMENT.lang.en;
      }
      const message = { ...langMessages.default, ...elementLang };
      messages[lang] = message;
      i18n.setLocaleMessage(lang, message);
      loadedLanguages.push(lang);
      return setI18nLanguage(lang);
    });
  });
}

function loadLangCDN(key, callback) {
  let script = document.createElement("script");
  script.type = "text/javascript";
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback("success");
      }
    };
  } else {
    script.onload = function () {
      callback("success");
    };
    script.onerror = function () {
      callback("fail");
    };
  }
  script.src = `//unpkg.com/element-ui/lib/umd/locale/${key}.js`;
  const head = document.getElementsByTagName("head")[0];
  head.appendChild(script);
}

export default i18n;

// json 转数组
/* function formatJson(parentKey = "", obj) {
  let list = [];
  const keys = Object.keys(obj);
  keys.forEach((key) => {
    const value = obj[key];
    console.log(typeof value);
    if (typeof value === "object") {
      list = list.concat(
        formatJson(`${parentKey === "" ? "" : parentKey + "."}${key}`, obj[key])
      );
    } else {
      list.push({
        Key: `${parentKey === "" ? "" : parentKey + "."}${key}`,
        Message: obj[key],
      });
    }
  });
  return list;
}
const data = require("./ua");
const jsonList = formatJson("", data.default);
console.log("平展json对象", JSON.stringify(jsonList)); */

/* const data = require('@/assets/json/language.js')
function arraytoObject() {
  let enJson = {}
  data.default.forEach((langItem) => {
    const keys = langItem.Key.split('.')
    const obj = formatObject(enJson, keys, langItem.Message_UA)
    Object.assign(enJson, obj)
    // console.log(enJson);
  })
  return enJson
}
function formatObject(rootObj = {}, keys, value) {
  let obj = {}
  if (keys.length !== 1) {
    let newKeys = keys.slice(1)
    let newRoot = rootObj[keys[0]]
    obj[keys[0]] = formatObject(newRoot, newKeys, value)
  } else {
    obj[keys[0]] = value
  }
  Object.assign(rootObj, obj)
  return rootObj
}
const strObj = arraytoObject()

console.log(JSON.stringify(strObj)) */

//深度合并对象
/* const zhMessage = require('./zh').default
const trMessage = require('./ua').default

function deepMerge(obj1, obj2) {
  let key
  for (key in obj2) {
    // 如果target(也就是obj1[key])存在，且是对象的话再去调用deepMerge，否则就是obj1[key]里面没这个对象，需要与obj2[key]合并
    // 如果obj2[key]没有值或者值不是对象，此时直接替换obj1[key]
    obj1[key] =
      obj1[key] && obj1[key].toString() === '[object Object]' && obj2[key] && obj2[key].toString() === '[object Object]'
        ? deepMerge(obj1[key], obj2[key])
        : (obj1[key] = obj2[key])
  }
  return obj1
}

console.log(JSON.stringify(deepMerge(zhMessage, trMessage)))
 */
