<template>
  <div id="app">
    <router-view />
    <Notice />
    <CountryDialog />
  </div>
</template>

<script>
import Notice from "@/components/Notice.vue";
import CountryDialog from "@/components/CountryDialog.vue";
import { _isMobile } from "@/utils";
import { mapGetters } from "vuex";
export default {
  components: { Notice, CountryDialog },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["valveBaseInfo", "language"]),
  },
  async created() {
    const isMobile = _isMobile();
    if (isMobile !== null) {
      window.open("http://sanhuaselector.com/h5/", "_self");
      return false;
    }
    await this.appInit();
    this.$store.dispatch("app/setAppInited", true);
  },
  mounted() {
    this.$nextTick(() => {
      const searchData = require("@/assets/json/china-search.json");
      localStorage.setItem("searchData", JSON.stringify(searchData));
    });
  },
  methods: {
    async appInit() {
      try {
        const { valveBaseInfo } = this;
        if (valveBaseInfo.length === 0) {
          const result = await this.$store.dispatch("valve/getValveBaseInfo");
          await this.$store.dispatch("app/getLoginAuth");
          const { location, areaName, countryList } = result.Data;
          let { country } = result.Data;
          const curCountry = Number(localStorage.getItem("curCountry"));
          const curLanguage = localStorage.getItem("app-anguage");
          const localUnitId = localStorage.getItem("UnitId");
          if (areaName === "NOCOUNTRY" && !curCountry) {
            this.$store.dispatch("app/setLangShow", true);
            this.$store.dispatch("app/setNoCountry", true);
          } else if (curCountry) {
            const localCountry = countryList.find((i) => i.Id === curCountry);
            location.Country = localCountry.Country;
            country = localCountry;
          }
          if (country && country.Language) {
            const { Language, Unitid } = country;
            await this.$store.dispatch(
              "app/setLanguage",
              curLanguage || Language
            );
            await this.$store.dispatch("app/setUnitId", localUnitId || Unitid);
            await this.$store.dispatch("app/setCountry", country.Id);
            await this.$store.dispatch(
              "app/setCountryNationalFlag",
              country.NationalFlag
            );
            await this.$store.dispatch("valve/getValveBaseInfo");
          }
          await this.$store.dispatch("recommend/getRecommend");
          await this.$store.dispatch("app/getConfig");
        }
      } catch (error) {
        console.log(error);
        this.$message.error("App Init Error");
      }
    },
  },
};
</script>

<style>
#nprogress .bar {
  background: rgb(0, 247, 255) !important;
  height: 3px;
}
</style>
<style scoped></style>
