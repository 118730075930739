export default {
  home: {
    title: "三花“发现者”选型系统(网页版)",
    tips: "总有一款适合您",
    searchPlace: "请输入搜索内容...",
    Refrigerant: "预选制冷剂和认证",
    Safetycer: "认证证书",
    RefrigerantSelect: "请选择制冷剂",
    ProductCategory: "产品大类",
    Application: "系统应用",
    login: "登录",
    gawRecordNo: "浙公网安备 33062402000765号",
    recordNo: "@2022 三花选型系统版权信息 浙ICP备2022001330号-1",
    prePage: "上一页",
    nextPage: "下一页",
    Reselect: "重新选择",
    systemType: "类型：",
    home: "首页",
    selection: "产品选型",
    selectionList: "选型清单",
    unitChange: "单位设置",
    unitUtils: "换算工具",
    absolutePre: "绝对压力",
    mobiletips: "请使用PC端打开！",
    Collection: "收藏",
    myCollection: "我的收藏",
    noCollection: "暂无收藏",
    delete: "删 除",
    cancel: "取 消",
    noSetting: "清除设置",
    confirm: "确 认",
    selectAll: "全 选",
    resetPassword: "重置密码",
    changePhone: "修改手机号",
    changeEmail: "修改邮箱",
    logout: "退出登录",
  },
  login: {
    tips: "请登录您的账户",
    form: {
      username: "用户名：",
      password: "密码：",
      passwordAgain: "确认密码：",
      tel: "电话：",
      country: "国家：",
      area: "地区：",
      email: "邮箱",
      authType: "验证方式",
      authByPhone: "手机号验证 Tips:暂只支持中国国内手机号",
      authByEmail: "邮箱验证",
      code: "验证码",
      sendCode: "发送验证码",
      countDown: "秒后重试",
      usernameRequired: "请输入用户名",
      passwordRequired: "请输入密码",
      emailRequired: "请输入邮箱地址",
      passwordAgainRequired: "请再次输入密码",
      passwrodAgainInconsistent: "两次输入密码不一致!",
      telRequired: "请输入联系方式",
      incorrectPhoneFormat: "错误的手机号格式",
      incorrectEmailFormat: "错误的邮箱格式",
      codeRequired: "请输入验证码",
      countryRequired: "请选择国家",
      areaRequired: "请选择地区",
      usenameLength: "账号长度必须在4-16位之间",
      usenameError: "账号请使用 字母,数字,下划线,减号",
      passwordLength: "密码长度必须大于等于8位",
      passwordError: "密码必须包含大小写字母、数字、非单词字符的特殊字符",
    },
    submit: "提交",
    toHome: "返回首页",
    verifyIdentity: "身份验证",
    authentication: "请进行身份验证",
    login: "登录",
    resetPassword: "重置密码",
    register: "注册",
    noLogin: "暂不登录",
    confirmTitle: "提示",
    confirmContent: "登录可以获得更好的使用体验,是否确认不登录?",
    registerConfirmContent: "注册登录可以获得更好的使用体验,是否确认不注册?",
    confirmText: "确定",
    cancelText: "取消",
    registerUnit: "三花商用",
    registerTips: "请填写注册信息",
    noRegister: "暂不注册",
    hasUser: "已有账号，去登录",
    forgotPassword: "忘记密码？",
    authStep: {
      auth: "身份验证",
      changeInfo: "修改信息",
      changeFinish: "修改完成",
    },
  },
  selector: {
    home: "首页",
    condition: "工况",
    Refrigerant: "制冷剂",
    NormalType: "动作类型",
    WidthType: "是否带充注端",
    FrequencyType: "定频还是变频",
    EqualType: "内平衡外平衡",
    synchro: "同步",
    open: "展开",
    putAway: "收起",
    pos: "安装位置",
    ProductTableTitle: "选择产品系列",
    radio: "选定",
    msg: "提示",
    search: "查询",
    tabPane: {
      performance: "性能曲线",
      product: "选择产品",
      code: "代码选择",
      info: "运行参数",
    },
    productTabPane: {
      product: "产品型号",
      coil: "线圈型号",
    },
    position: {
      A: "液体管路A",
      C: "吸气管路C",
      D: "排气管路D",
    },
    selectTips: {
      OptimalOption: "最优项",
      AvailableOptions: "可选项",
      ProhibitedOptions: "禁选项",
    },
  },
  info: {
    export: "导出",
    Point: {
      Qe: "制冷量Qe",
      Qh: "制热量Qh",
      MassFlow: "质量流量M",
      Pe: "蒸发压力Pe",
      Pk: "冷凝压力Pk",
      pressure: "压力",
      temperature: "温度",
      Density: "密度",
      Enthalphy: "比焓",
      Entropy: "比熵",
      Dryness: "干度",
    },
    titleTip: "P", // Point
    posTitle: {
      1: "压缩机吸气",
      2: "压缩机排气",
      "2s": "冷凝露点",
      "3s": "冷凝泡点",
      "3p": "冷凝器出口",
      3: "膨胀阀进口",
      "4s": "蒸发器泡点",
      4: "膨胀阀出口",
      "1s": "蒸发露点",
    },
  },
  lineChart: {
    dp: "压差",
    open: "开度",
    load: "负荷",
  },
  message: {
    resetPasswordSuccess: "重置密码成功",
    CalculationFailed: "计算失败",
    registerSuccess: "注册成功",
    noData: "未查询到数据",
    saveSuccess: "保存成功",
    addSuccess: "添加成功!",
    noFrequencyType: "请选择定频或者变频",
    noSelection: "请进行选型",
    loginFirst: "请先登录",
    loginOutSuccess: "退出成功",
    deleteItem: "是否删除该产品？",
    deleteSuccess: "删除成功",
    cancelDelete: "已取消删除",
    unitName: "请输入自定义单位名称",
    unitAtLeastOne: "至少设置一个单位",
    exportSuccess: "导出成功",
    exportError: "导出失败",
    selectProduct: "请选择产品",
    inputMore: "请输入更多内容",
    nocalc: "无法计算",
    exportLoading: "正在获取数据",
    clearSelection: "是否清空清单？",
    clearSelected: "是否删除选中产品？",
    exportAll: "是否导出全部清单？",
    selectExport: "请选择需要导出的产品",
    exportSelected: "是否导出选择的产品？",
    selectedIsEmpty: "选择的清单不能为空",
    changeSuccess: "修改成功",
    authTypeError: "请选择正确的验证方式",
    sendCodeSuccess: "验证码已发送，请注意查收",
    verifyIdentitySuccess: "身份验证成功",
    pleaseSelect: "请进行产品选型添加",
  },
  preformance: {
    addSelection: "添加至选型清单",
    document: "文档和视觉效果",
    enclosure: "附件及备件",
  },
  condition: {
    Refrigerant: "制冷剂",
  },
  export: {
    condition: "工况",
    img: "图片",
    line: "性能曲线",
    detail: "产品参数",
    run: "运行参数",
    document: "视觉文档",
    Product: "型号",
    Coil: "线圈",
    back: "返回",
    export: "导出",
    productName: "项目名称",
    remarks: "备注",
    userName: "创建人",
    exportTime: "时间",
    noSelectionProduct: "-无选型产品",
    productDetail: "产品参数",
    productSerial: "产品系列",
    runDetail: "运行参数",
    dealers: "附近经销商",
    exportLoading: "正在导出。。。",
    serialImage: "系列图",
    productImage: "产品图",
    posImage: "安装位置图",
  },
  details: {
    goShoping: "前往商城",
    export: "导出",
    condition: "工况",
    productValve: "产品名称",
    productSerial: "产品系列",
    productName: "产品型号",
    productParameters: "产品参数",
    total: "总计",
    unit: "件",
    saveSelection: "保存清单",
    confirmTips: "提示",
  },
  sort: {
    ProductCategory: "产品大类",
    productSeries: "产品系列",
    goShoping: "前往商城",
    showInfo: "查看详情",
    All: "全部",
    added: "已添加产品",
    noAdd: "未添加产品",
    add: "添加至清单",
    search: "查询",
    screen: "条件筛选",
    searchName: "名称",
    coolRange: "冷量范围：",
    noDetail: "无",
    address: "库存地址：",
    document: "文档和视觉效果",
  },
  form: {
    pleaseEnter: "请输入",
    pleaseSelect: "请选择",
    pleaseSelectDate: "选择日期",
    startDate: "开始日期",
    endDate: "结束日期",
    separator: "至",
    dialogTitle: "提示",
    cancel: "取 消",
    confirm: "确 定",
    uploadOver: "上传头像图片大小不能超过 2MB!",
    calculation: "计 算",
  },
  langSelect: {
    tips: "请选择国家和语言",
    language: "语言",
    country: "国家或地区",
  },
  unitGroup: {
    title: "请选择单位制",
    unitid1: "美国单位制",
    unitid2: "国际通用单位制",
    newUnitSystem: "新增单位制",
    baseUnit: "基础单位制",
    customUnit: "自定义单位制",
    unitName: "名称",
    save: "保存",
    Temp: "温度",
    Temp2: "过冷/过热度",
    Press: "压力",
    Volume: "体积流量",
    Pressure: "压力",
    Power: "能量",
    Flow: "质量流量",
    CoolCapacity: "制冷量",
    HeatCapacity: "制热量",
    Velocity: "速度",
    VolumeFlow: "体积流量",
    MassFlow: "质量流量",
    Size: "尺寸",
    Density: "密度",
    Enthalpy: "焓",
    Entropy: "熵",
    FlowFactor: "流量系数",
  },
  document: {
    DataList: "数据清单",
    Manual: "手册指南",
    Photo: "照片",
    DataLModelist: "模型",
  },
  notice: {
    title: "推荐信息",
    noShowAgain: "不再显示",
  },
  selection: {
    cancel: "取消",
    clear: "清理",
    clearSelected: "清除选择",
    deleteSelected: "删除选择",
    removeAll: "清空",
    exportAll: "导出全部",
  },
};
