<template>
  <el-dialog
    :title="$t('notice.title')"
    custom-class="notice-dialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :visible.sync="noticeShow"
    :before-close="close"
    width="61.8%"
    top="13vh"
  >
    <div class="notice-box">
      <template v-for="(recommend, index) in showlist">
        <el-divider :key="recommend.Id + 'divider'" v-if="index !== 0" />
        <div class="notice" :key="recommend.Id">
          <div class="imageBox">
            <el-image
              class="notice-image"
              :src="recommend.imgUrl"
              fit="contain"
            />
          </div>
          <div class="noticeInfo">
            <div class="title">
              <span>{{ recommend.Title }}</span>
              <!-- <i
              class="el-icon-close close"
              @click="closeItem(recommend, index)"
            /> -->
            </div>
            <div class="info">
              <template v-for="field in recommend.fields">
                <div
                  class="info-item"
                  :key="field.name"
                  v-if="showFields.includes(field.name)"
                >
                  <span>{{ field.title }}：</span>
                  <span>{{ recommend.product[field.name] }}</span>
                </div>
              </template>
            </div>
            <el-divider></el-divider>
            <div class="content">{{ recommend.Content }}</div>

            <div class="button">
              <el-button
                v-if="recommend.LinkType !== LinkType.NoJump.value"
                size="medium"
                type="primary"
                @click="goInfo(recommend)"
              >
                {{ $t("sort.showInfo") }}
              </el-button>
              <el-button
                size="medium"
                type="primary"
                @click="goShoping"
                v-if="recommend.LinkType !== LinkType.Link.value"
              >
                {{ $t("sort.goShoping") }}
              </el-button>
              <el-checkbox class="noShowAgain" v-model="recommend.noShow">
                {{ $t("notice.noShowAgain") }}
              </el-checkbox>
            </div>
          </div>
        </div>
      </template>
    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "NoticeDialog",
  data() {
    return {
      noticeShow: false,
      showlist: [],
      noShowAgain: false,
      showFields: ["Safetycer", "Refrigerant_S", "Location"],
    };
  },
  computed: {
    ...mapState({
      show: (state) => state.recommend.show,
      recommendList: (state) => state.recommend.recommendList,
    }),
    ...mapGetters(["webConfig", "region", "valveDic"]),
  },
  watch: {
    show: {
      handler: function (nVal) {
        this.noticeShow = nVal;
        if (nVal) {
          this.showlist = this.recommendList.filter(
            (recommend) => !recommend.noShow
          );
        }
      },
    },
  },
  methods: {
    close() {
      this.showlist.forEach((recommend) => {
        if (recommend.noShow) {
          console.log(recommend.noShow);
          this.$store.dispatch("recommend/setNoShowAgain", recommend);
        }
      });
      this.$store.dispatch("recommend/setShow", false);
    },
    closeItem(recommend, index) {
      this.showlist.splice(index, 1);
      if (recommend.noShow) {
        this.$store.dispatch("recommend/setNoShowAgain", recommend);
      }
      if (this.showlist.length === 0) {
        this.close();
      }
    },
    goShoping() {
      const { region, webConfig } = this;
      const linkSplit = webConfig["ShopLink-" + region].Content.split(";");
      const link = linkSplit.join("");
      window.open(link, "_blank");
    },
    goInfo(recommend) {
      const { LinkType } = this;
      console.log(recommend);
      if (recommend.LinkType === LinkType.Link.value) {
        return window.open(recommend.LinkUrl, "_blank");
      }
      const { valveDic } = this;
      const valve = valveDic[recommend.Valveid];
      const params = {
        valveName: valve.Classname,
        keyword: recommend.PartNumber,
      };
      this.$router.push({ name: "sort", query: params });
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.notice-box {
  width: 100%;
  height: 61.8vh;
  overflow-y: auto;
}
.notice {
  display: flex;
  & + .notice {
    margin-top: 40px;
  }
  .imageBox,
  .notice-image {
    width: 300px;
    height: 200px;
  }
  .noticeInfo {
    margin-left: 10px;
    flex: 1;
    position: relative;
    .title {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      .close {
        float: right;
        vertical-align: top;
      }
    }
    .info {
      display: flex;
      margin-top: 10px;
      flex-wrap: wrap;
      &-item {
        & + .info-item {
          margin-left: 20px;
        }
      }
    }
    .content {
      margin-bottom: 10px;
      line-clamp: 3;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      box-orient: vertical;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .button {
      width: 100%;
      margin-top: 10px;
      padding-right: 10px;
    }
    .noShowAgain {
      float: right;
      vertical-align: top;
    }
  }
}
@media screen and (max-width: 1400px) {
  .notice {
    .imageBox,
    .notice-image {
      width: 200px;
      height: 133.333px;
    }
  }
}
</style>
<style lang="scss">
@import "@/assets/styles/theme.scss";
.notice-dialog {
  .el-dialog__header {
    background-color: $main-color;
    .el-dialog__title {
      color: #ffffff;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #ffffff;
    }
  }
}
</style>
