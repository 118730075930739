/*
 * @Author: Ljh <1483613982@qq.com>
 * @Date: 2022-06-22 17:23:10
 * @LastEditTime: 2022-07-17 13:42:52
 * @FilePath: \sanhua-selector\src\utils\selection.js
 * @Description:
 */
const KeyType = {
  Product: "SelectionProductV2",
  Condition: "SelectionConditionV2",
  ProductKey: "SelectionProductKeyV2",
  Selected: "SelectionSelectedListV2",
  Hashkey: "SelectionHashKeyV2",
};
export function getSelection(type) {
  const key = KeyType[type];
  return JSON.parse(localStorage.getItem(key));
}
export function setSelection(type, data) {
  const key = KeyType[type];
  return localStorage.setItem(key, JSON.stringify(data));
}
