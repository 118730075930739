import request from "@/utils/request";

export function calculate(data) {
  return request({
    url: "/api/ucselection/calculate",
    method: "post",
    data,
  });
}
export function getChartLine(data) {
  return request({
    url: "/api/ucselection/getchartline",
    method: "post",
    data,
  });
}

export function saveSelection(data) {
  return request({
    url: "/api/result/saveselection",
    method: "post",
    data,
  });
}

export function getCurrent(params) {
  return request({
    url: "/api/result/getcurrent",
    method: "get",
    params,
  });
}

export function getlist(params) {
  return request({
    url: "/api/result/getlist",
    method: "get",
    params,
  });
}
// /api/ucselection/tb_tkchanged
export function tkChange(data) {
  return request({
    url: "/api/ucselection/tb_tkchanged",
    method: "post",
    data,
  });
}

export function teChange(data) {
  return request({
    url: "/api/ucselection/tb_techanged",
    method: "post",
    data,
  });
}