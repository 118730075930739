import { getlist, saveSelection } from '@/api/selection'
import { productSort, deepClone } from '@/utils'
import { getSelection, setSelection } from '@/utils/selection'

// const productSort = function(product1, product2) {
//   if (product1.ProductType === "Coil" && product2.ProductType === "Product") {
//     return 1;
//   }else if(product1.conditionIndex !== -1){
//     return 1
//   }else {
//     return -1
//   }
// };
const state = {
  productlist: getSelection('Product') || [],
  conditionList: getSelection('Condition') || [],
  productKeyList: getSelection('ProductKey') || [],
  selectedSort: '',
  getSaveState: false,
  selectedList: getSelection('Selected') || [],
}

const mutations = {
  SET_PRODUCT_LIST: (state, productlist) => {
    setSelection('Product', productlist)
    state.productlist = productlist
  },
  SET_CONDITION_LIST: (state, conditionList) => {
    setSelection('Condition', conditionList)
    state.conditionList = conditionList
  },
  SET_PRODUCT_KEY_LIST: (state, productKeyList) => {
    setSelection('ProductKey', productKeyList)
    state.productKeyList = productKeyList
  },
  SET_SELECTED_SORT: (state, selectedSort) => {
    state.selectedSort = selectedSort
  },
  SET_GET_SAVE_STATE: (state, status) => {
    state.getSaveState = status
  },
  SET_SELECTED_LIST: (state, list) => {
    setSelection('Selected', list)
    state.selectedList = list
  },
}

const actions = {
  saveModelAndProduct({ commit, state }, { condition, product }) {
    let { productlist, productKeyList, conditionList } = state
    if (productKeyList.includes(product.PartNumber)) {
      const hasCondition = condition
        ? conditionList.findIndex((conditionItem) => conditionItem.Index === condition.Index)
        : -1
      if (hasCondition == -1) {
        conditionList.push(condition)
      }
      product.conditionIndex = condition ? condition.Index : -1
      productlist.forEach((productItem) => {
        if (productItem.PartNumber === product.PartNumber) {
          Object.assign(productItem, product)
        }
      })
    } else {
      if (condition) {
        const hasCondition = conditionList.findIndex((conditionItem) => conditionItem.Index === condition.Index)
        if (hasCondition == -1) {
          conditionList.push(condition)
        }
      }
      product.conditionIndex = condition ? condition.Index : -1
      productKeyList.push(product.PartNumber)
      productlist.push(product)
    }
    // productlist.sort(productSort)
    commit('SET_PRODUCT_LIST', productlist)
    commit('SET_CONDITION_LIST', conditionList)
    commit('SET_PRODUCT_KEY_LIST', productKeyList)
  },
  deleteProduct({ commit, state }, product) {
    const { productlist, productKeyList } = state
    var index = productlist.findIndex((obj) => obj.PartNumber === product.PartNumber)
    var keyIndex = productKeyList.findIndex((PartNumber) => PartNumber === product.PartNumber)
    productlist.splice(index, 1)
    productKeyList.splice(keyIndex, 1)

    commit('SET_PRODUCT_LIST', productlist)
    commit('SET_PRODUCT_KEY_LIST', productKeyList)
  },
  changeSelectedSort({ commit }, sortname) {
    commit('SET_SELECTED_SORT', sortname)
  },
  changeGetSaveState({ commit }, status) {
    commit('SET_GET_SAVE_STATE', status)
  },
  getSaveSelect({ commit, dispatch, state }, isLogin) {
    return new Promise((resolve, reject) => {
      const storageData = {}
      storageData.productlist = deepClone(state.productlist)
      storageData.conditionList = deepClone(state.conditionList)
      storageData.productKeyList = deepClone(state.productKeyList)
      getlist()
        .then((res) => {
          try {
            const { productList, conditions } = res.Data
            const conditionList = conditions ? [conditions] : []
            const keylist = productList.map((product) => {
              product.PartNumber = product.ProductName
              product.Sizename = product.SizeName
              product.conditionIndex = -1
              product.imageUrl = product.ImageUrl
                ? product.ImageUrl.indexOf('http') !== -1
                  ? product.ImageUrl
                  : product.ImageUrl.split(';')[0]
                : ''
              return product.PartNumber
            })
            // productList.sort(productSort)
            commit('SET_PRODUCT_KEY_LIST', keylist)
            commit('SET_PRODUCT_LIST', productList)
            commit('SET_CONDITION_LIST', conditionList)
            commit('SET_GET_SAVE_STATE', true)
          } catch (err) {
            console.error(err)
            reject(err)
          }
        })
        .then(() => {
          if (isLogin) {
            console.log('add product selection', storageData)

            storageData.productlist.forEach((sproduct) => {
              const conditionIndex = sproduct.conditionIndex
              const scondition = conditionIndex >= 0 ? storageData.conditionList[conditionIndex] : null
              delete sproduct.conditionIndex
              if (scondition) {
                scondition.Index = state.conditionList.length
              }
              const options = {
                product: sproduct,
                condition: scondition,
              }
              console.log(JSON.stringify(options))
              dispatch('saveModelAndProduct', options)
            })
          }
          if (state.productlist.length !== 0) {
            dispatch('saveUserSelection')
          }
          console.log('save user selection success')
          resolve()
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
    })
  },

  clearSelection({ commit, dispatch, rootState }, userClear = false) {
    const { token } = rootState.user
    commit('SET_PRODUCT_LIST', [])
    commit('SET_CONDITION_LIST', [])
    commit('SET_PRODUCT_KEY_LIST', [])
    if (token && userClear) {
      dispatch('saveUserSelection')
    }
  },
  saveUserSelection({ state, rootState }) {
    const { token } = rootState.user
    console.log(rootState, token)
    if (!token) {
      return false
    }
    return new Promise((resolve) => {
      const { productlist, conditionList } = state
      let conditionIndexList = []
      const plist = productlist.map((item) => {
        const { Valveid, ValveName, Serialid, Sizeid, Sizename, ImageUrl } = item
        const { SerialName, PartNumber, ProductName, conditionIndex, ProductType } = item
        let product = { ValveName, Sizeid, Sizename, SerialName }
        product.valveId = Valveid
        product.serialId = Serialid
        product.productName = PartNumber || ProductName
        product.ProductType = ProductType
        product.ImageUrl = ImageUrl
        if (conditionIndex > -1) {
          product.conditionIndex = conditionIndex
          conditionIndexList.push(conditionIndex)
        } else {
          product.conditionIndex = -1
        }
        return product
      })
      const clist = conditionList.map((condition, index) => {
        if (conditionIndexList.includes(index)) {
          return { ...condition, Index: null }
        } else {
          return null
        }
      })
      const params = { conditionList: clist, productList: plist }

      saveSelection(params).then((res) => {
        resolve(res)
      })
    })
  },
  saveSelectedList({ commit }, list) {
    commit('SET_SELECTED_LIST', list)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
