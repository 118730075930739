<template>
  <el-dialog
    :title="$t('home.unitUtils')"
    :visible.sync="dislogShow"
    append-to-body
    :close-on-click-modal="false"
    width="30%"
  >
    <div class="unitutilsBox">
      <el-form :model="unitForm" label-width="auto">
        <el-form-item :label="$t('home.Refrigerant')">
          <el-select v-model="unitForm.refrigerant">
            <el-option
              v-for="(refrigerant, index) in refrigerantList"
              :key="index"
              :label="refrigerant.text"
              :value="refrigerant.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('home.absolutePre')">
          <el-switch v-model="absolute"></el-switch>
        </el-form-item>
        <div class="unitBox">
          <div class="old">
            <el-cascader
              style="width: 100%"
              v-model="unitForm.oldUnitType"
              :options="optionMap.unitTypeMap"
              @change="oldUnitChange"
            ></el-cascader>

            <el-input
              type="number"
              class="textInput"
              :placeholder="$t('form.pleaseEnter')"
              v-model="unitForm.oldValue"
              @blur="calculation('old')"
            ></el-input>
          </div>
          <div class="icon"><i class="el-icon-sort" /></div>
          <div class="new">
            <el-cascader
              style="width: 100%"
              v-model="unitForm.newUnitType"
              :options="optionMap.newUnitTypeMap"
              @change="calculation"
            ></el-cascader>
            <el-input
              type="number"
              class="textInput"
              @blur="calculation('new')"
              v-model="unitForm.newValue"
              :placeholder="$t('form.pleaseEnter')"
            ></el-input>
          </div>
        </div>
        <div class="nocalc" v-show="noCalc">{{ $t("message.nocalc") }}</div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dislogShow = false">
        {{ this.$t("form.cancel") }}
      </el-button>
      <!-- <el-button @click="calculation" type="primary">
        {{ this.$t('form.calculation') }}
      </el-button> -->
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { convertTempToPress, convertPressToTemp } from "@/api/UnitConvert";
import unitUtils from "@/utils/unit";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dislogShow: false,
      unitForm: {
        refrigerant: "",
        oldUnitType: ["Temp", "℃"],
        newUnitType: ["Pressure", "bar"],
        oldValue: "",
        newValue: "",
      },
      noCalc: false,
      unitList: [],
      optionMap: {
        newUnitTypeMap: [],
        unitTypeMap: [],
      },
      absolute: false,
      unitTypeList: [
        { name: "Temp", value: "Temp" },
        { name: "Pressure", value: "Pressure" },
        { name: "Power", value: "Power" },
        { name: "Flow", value: "Flow" },
        { name: "Size", value: "Size" },
        { name: "Volume", value: "Volume" },
        { name: "Velocity", value: "Velocity" },
        { name: "Density", value: "Density" },
        { name: "Enthalpy", value: "Enthalpy" },
        { name: "Entropy", value: "Entropy" },
      ],
    };
  },
  computed: {
    ...mapGetters(["refrigerantList", "language", "unitId"]),
  },
  watch: {
    show: {
      handler: function (nVal) {
        this.dislogShow = nVal;
      },
    },
    absolute: {
      handler: function () {
        this.calculation();
      },
    },
    language: {
      handler: function () {
        this.loadData();
      },
    },
    dislogShow: {
      handler: function (nVal) {
        if (!nVal) {
          this.$emit("close", false);
        }
      },
    },
    unitId: {
      handler: function () {
        this.setDefaultUnit();
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadData();
    });
  },
  methods: {
    loadData() {
      const { refrigerantList, unitTypeList } = this;
      const unitdata = require("@/assets/json/data.json");
      this.optionMap.unitTypeMap = unitTypeList.map((unitType) => {
        const unitlist = unitdata[`${unitType.name}Units`];
        return {
          ...unitType,
          disabled: false,
          label: this.$t(`unitGroup.${unitType.name}`),
          children: unitlist.map((unit) => {
            return { ...unit, label: unit.value };
          }),
        };
      });
      this.optionMap.newUnitTypeMap = this.optionMap.unitTypeMap;
      this.setDefaultUnit();
      // this.oldUnitChange(this.unitForm.oldUnitType)
      if (refrigerantList && refrigerantList[0]) {
        this.unitForm.refrigerant = refrigerantList[0].value;
      }
    },
    setDefaultUnit() {
      const { unitId } = this;
      if (unitId == 2) {
        this.unitForm.oldUnitType = ["Temp", "℃"];
        this.unitForm.newUnitType = ["Pressure", "bar"];
      }
      if (unitId == 1) {
        this.unitForm.oldUnitType = ["Temp", "℉"];
        this.unitForm.newUnitType = ["Pressure", "Psi"];
      }
    },
    oldUnitChange() {
      // const unitType = e[0]
      // const { unitTypeMap } = this.optionMap
      // this.unitForm.newUnitType = e
      // const list = ['Temp', 'Pressure']
      // if (list.includes(unitType)) {
      //   this.optionMap.newUnitTypeMap = unitTypeMap.map((unitTypeItem) => {
      //     const disabled = !list.includes(unitTypeItem.value)
      //     return {
      //       ...unitTypeItem,
      //       disabled
      //     }
      //   })
      // } else {
      //   this.optionMap.newUnitTypeMap = unitTypeMap.map((unitTypeItem) => {
      //     const disabled = unitType !== unitTypeItem.value
      //     return {
      //       ...unitTypeItem,
      //       disabled
      //     }
      //   })
      // }
      const { oldValue } = this.unitForm;
      if (!this.$utils.isEmpty(oldValue)) {
        this.calculation();
      }
    },
    calculation(source) {
      console.log(source);
      const { refrigerant, oldUnitType, newUnitType } = this.unitForm;
      const { oldValue, newValue } = this.unitForm;
      const { absolute } = this;
      const list = ["Temp", "Pressure"];
      const unitType = oldUnitType[0];
      const oldUnit = oldUnitType[1];
      const newType = newUnitType[0];
      const newUnit = newUnitType[1];
      if (
        (list.includes(unitType) && !list.includes(newType)) ||
        (list.includes(newType) && !list.includes(unitType)) ||
        (!list.includes(unitType) &&
          !list.includes(newType) &&
          unitType !== newType)
      ) {
        this.noCalc = true;
        return false;
      }
      this.noCalc = false;
      if (
        source === "new"
          ? this.$utils.isEmpty(newValue)
          : this.$utils.isEmpty(oldValue)
      ) {
        return false;
      }
      if (
        list.includes(source === "new" ? newType : unitType) &&
        unitType !== newType
      ) {
        const params = {
          refrigerant,
          absolute,
          oldunit: source === "new" ? newUnit : oldUnit,
          oldvalue: source === "new" ? newValue : oldValue,
          newunit: source === "new" ? oldUnit : newUnit,
        };

        if ((source === "new" ? newType : unitType) === "Temp") {
          convertTempToPress(params).then((res) => {
            const { Val } = res.Data;
            if (source === "new") {
              this.unitForm.oldValue = Val.toFixed(2);
            } else {
              this.unitForm.newValue = Val.toFixed(2);
            }
          });
        } else if ((source === "new" ? newType : unitType) === "Pressure") {
          convertPressToTemp(params).then((res) => {
            const { Val } = res.Data;
            if (source === "new") {
              this.unitForm.oldValue = Val.toFixed(2);
            } else {
              this.unitForm.newValue = Val.toFixed(2);
            }
          });
        }
      } else {
        const params = {
          oldunit: source === "new" ? newUnit : oldUnit,
          oldvalue: source === "new" ? newValue : oldValue,
          newunit: source === "new" ? oldUnit : newUnit,
        };
        unitUtils[source === "new" ? newType : unitType](
          params,
          (res) => {
            const { Val } = res.Data;
            if (source === "new") {
              this.unitForm.oldValue = Val.toFixed(2);
            } else {
              this.unitForm.newValue = Val.toFixed(2);
            }
          },
          () => {
            this.$message.show({
              message: this.$t("message.CalculationFailed"),
              type: "error",
            });
          }
        );
      }
    },
  },
};
</script>

<style lang="scss">
.unitutilsBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    margin: 0 10px;
    padding: 10px;
    text-align: center;
    .el-icon-sort:before {
      font-size: 24px;
    }
  }

  .nocalc {
    color: red;
    margin: 10px 0;
  }
  .textInput {
    margin-top: 10px;
    height: 80px;
    .el-input__inner {
      height: 80px;
    }
  }
}
</style>
