import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import Cookie from 'js-cookie'
import router from '../router'

// create an axios instance
// eslint-disable-next-line no-undef
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
})
service.defaults.retry = 3
service.defaults.retryDelay = 2000
// request interceptor
service.interceptors.request.use(
  (config) => {
    // const lang = navigator.language
    config.headers['LANG'] = Cookie.get('language') || 'cn'
    config.headers['Unitid'] = Cookie.get('UnitId') || 2
    if (store.state.app.countryName) {
      config.headers['Country'] = store.state.app.countryName
    }
    if (store.getters.token) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    // console.log(response)
    if (response.status === 401) {
      MessageBox.confirm(
        'You have been logged out, you can cancel to stay on this page, or log in again',
        'Confirm logout',
        {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
        .then(() => {
          store.dispatch('user/resetToken').then(() => {
            store.dispatch('selection/clearSelection')
            // location.reload()
            console.log(router)
            router.push({ name: 'login', query: {} })
          })
        })
        .catch(() => {
          store.dispatch('user/resetToken')
          store.dispatch('selection/clearSelection')
        })
      return Promise.reject(new Error(res.ErrorMsg || 'Error'))
    } else if (res.Result !== 0) {
      Message({
        message: res.ErrorMsg || 'Error',
        type: 'error',
        duration: 5 * 1000,
      })

      // 100:无token信息 ; 50012: Other clients logged in; 50014: Token expired;
      if (res.Result === 100 || res.Result === 50012 || res.Result === 50014) {
        // to re-login
        MessageBox.confirm(
          'You have been logged out, you can cancel to stay on this page, or log in again',
          'Confirm logout',
          {
            confirmButtonText: 'Re-Login',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }
        )
          .then(() => {
            store.dispatch('user/resetToken').then(() => {
              store.dispatch('selection/clearSelection')
              // location.reload()
              console.log(router)
              router.push({ name: 'login', query: {} })
            })
          })
          .catch(() => {
            store.dispatch('user/resetToken')
            store.dispatch('selection/clearSelection')
          })
      }
      return Promise.reject(new Error(res.ErrorMsg || 'Error'))
    } else {
      return res
    }
  },
  (error) => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000,
    })
    store.dispatch('user/resetToken')
    return Promise.reject(error)
  }
)

export default service
