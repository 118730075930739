export default {
  home: {
    title: "Sanhua QuickFinder Selector (Web)",
    tips: "Find the Right Products",
    searchPlace: "Search for Products, Part Number…",
    Refrigerant: "Pre-Selection",
    Safetycer: "Approvals",
    RefrigerantSelect: "Refrigerants",
    ProductCategory: "by Product",
    Application: "by Application",
    login: "Sign in",
    gawRecordNo:
      "Zhejiang Internet Security Management Registration No. 33062402000765",
    recordNo: "© Sanhua. All rights reserved 2022",
    prePage: "Previous Page",
    nextPage: "Next Page",
    Reselect: "BACK",
    systemType: "Category ",
    home: "Home",
    selection: "Product Selection",
    selectionList: "Selected List",
    unitChange: "Units",
    unitUtils: "Conversion tool",
    absolutePre: "Absolute pressure",
    mobiletips: "Please open it with PC",
    Collection: "Add",
    myCollection: "My Favorites",
    noCollection: "No Favorites",
    delete: "Delete",
    cancel: "Cancel",
    noSetting: "Reset",
    confirm: "Confirm",
    selectAll: "Select All",
  },
  login: {
    tips: "Sign in here",
    form: {
      username: "User Name",
      password: "Password",
      passwordAgain: "Confirm Password",
      tel: "Phone",
      country: "Your Country",
      area: "Region",
      usernameRequired: "input your username",
      passwordRequired: "input your password",
      passwordAgainRequired: "confirm your password",
      passwrodAgainInconsistent:
        "The password and confirmation password are different!",
      telRequired: "input your phone number",
      countryRequired: "select your country",
      areaRequired: "select your region",
      usenameLength: "Account length must be between 4-16 digits",
      usenameError:
        "Please use letters, numbers, underscores and minus signs for the account",
      passwordLength: "Password length must be greater than or equal to 8 bits",
      passwordError:
        "The password must contain uppercase and lowercase letters, numbers, and special characters other than word characters",
    },
    login: "Sign In",
    register: "Registration",
    noLogin: "Sign in later",
    confirmTitle: "Message",
    confirmContent:
      "You can get more functions to sign in, Do you continue to work without signing in?",
    registerConfirmContent:
      "You can get more functions to sgin up, Do you confirm  to work without signing up ?",
    confirmText: "OK",
    cancelText: "Cancel",
    registerUnit: "SANHUA Commercial",
    registerTips: "Please fill in your information",
    noRegister: "Registration later",
    hasUser: "I have an account",
  },
  selector: {
    home: "Home",
    condition: "Operating Conditions",
    synchro: "Sync",
    open: "Unfold",
    putAway: "Fold",
    pos: "Position ",
    NormalType: "Function",
    Refrigerant: "Refrigerant",
    WidthType: "Whether with filling end",
    FrequencyType: "FrequencyType",
    EqualType: "EqualType",
    ProductTableTitle: "Select the Model",
    radio: "Selected",
    msg: "Message",
    search: "Calculate",
    tabPane: {
      performance: "Performance Curve",
      product: "Select Part Number",
      code: "",
      info: "Performance Details",
    },
    productTabPane: { product: "Product P/N", coil: "Coil P/N" },
    position: {
      A: "Liquid Line A",
      C: "Suction Line C",
      D: "Discharge Line D",
    },
    selectTips: {
      OptimalOption: "Optimal Option",
      AvailableOptions: "Available Options",
      ProhibitedOptions: "Prohibited Options",
    },
  },
  info: {
    export: "Report",
    Point: {
      Qe: "Cooling Capacity Qe",
      Qh: "Heating Capacity Qh",
      MassFlow: "Mass Flow M",
      Pe: "Evaporating Pressure Pe",
      Pk: "Condensing Pressure Pk",
      pressure: "Pressure (a)",
      temperature: "Temperature",
      Density: "Density",
      Enthalphy: "Enthalpy",
      Entropy: "Entropy",
      Dryness: "Dryness",
    },
    titleTip: "P",
    posTitle: {
      1: "Compressor Suction",
      2: "Compressor Discharge",
      3: "Expansion Valve Inlet",
      4: "Expansion Valve Outlet",
      "2s": "Condensation Dew Point",
      "3s": "Condensation Bubble Point",
      "3p": "Condenser Outlet",
      "4s": "Evaporation Bubble Point",
      "1s": "Evaporation Dew Point",
    },
  },
  lineChart: { dp: "DP", open: "Opening", load: "Load" },
  message: {
    noData: "No data is availble",
    saveSuccess: "Saved successfully",
    addSuccess: "Added successfully",
    noFrequencyType: "Please select between fixed or variable speed compressor",
    noSelection: "Please select at least one part number",
    loginFirst: "Please sign in firstly",
    loginOutSuccess: "Sign out successfully",
    deleteItem: "Do you confirm to delete it from the list ?",
    deleteSuccess: "Deleted successfully",
    cancelDelete: "Undelete",
    unitName: "Name your unit set",
    unitAtLeastOne: "Set at least one unit",
    exportSuccess: "Report downloaded successfully",
    exportError: "Report download is failed",
    selectProduct: "Please select the part number",
    inputMore: "Please enter more",
    nocalc: "Unable to calculate",
    exportLoading: "getting data",
    clearSelection: "Clear the list?",
    clearSelected: "Do you want to delete the selected product?",
    exportAll: "Do you want to export the entire list?",
    selectExport: "Please select the product to be exported",
    exportSelected: "Export selected products?",
    selectedIsEmpty: "The selected list cannot be empty",
  },
  preformance: {
    addSelection: "Add to selection list",
    document: "Documentations",
    enclosure: "Accessories & Spare Parts",
  },
  condition: { Refrigerant: "Refrigerant" },
  export: {
    condition: "Operating Conditions",
    img: "Photos",
    line: "Performance Curve",
    detail: "Product Details",
    run: "Performance Details",
    document: "Documentations",
    Product: "Part Number",
    Coil: "Coil P/N",
    back: "Back",
    export: "Download",
    productName: "Project Name",
    remarks: "Comments",
    userName: "Created by",
    exportTime: "Created Date",
    noSelectionProduct: "-Prodct is not calculated",
    productDetail: "Product Details",
    productSerial: "Selection Result",
    runDetail: "Performance Details",
    dealers: "Distributors Nearby",
    exportLoading: "Downloading…",
    serialImage: "Series Image",
    productImage: "Product Image",
    posImage: "installation location Image",
  },
  details: {
    goShoping: "Find distributors nearby",
    export: "Report",
    condition: "Operating Conditions",
    productValve: "Product Name",
    productSerial: "Model",
    productName: "Part Number",
    productParameters: "Product Details",
    total: "Total",
    unit: "Added",
    saveSelection: "Save the list",
    confirmTips: "Message",
  },
  sort: {
    ProductCategory: "Product Category",
    productSeries: "Product Series",
    goShoping: "Go to Sanhuashop",
    showInfo: "More Details",
    All: "All",
    added: "Selected",
    noAdd: "Not Selected",
    add: "Add to selection list",
    search: "Search",
    screen: "Filter",
    searchName: "input Part Number",
    coolRange: "Capacity Range",
    noDetail: "No Data",
    address: "Inventory Location",
    document: "Documentations",
  },
  form: {
    pleaseEnter: "input",
    pleaseSelect: "please select",
    pleaseSelectDate: "Date",
    startDate: "Start Date",
    endDate: "End Date",
    separator: "to",
    dialogTitle: "Message",
    cancel: "Cancel",
    confirm: "OK",
    calculation: "Calculate",
    uploadOver: "The size of photo should not exceed 2MB!",
  },
  langSelect: {
    tips: "Please select your country and language",
    language: "Language",
    country: "Country or Region",
  },
  unitGroup: {
    title: "Please select unit set",
    unitid1: "America",
    unitid2: "International",
    newUnitSystem: "Add new unit set",
    baseUnit: "Basic unit system",
    customUnit: "Custom system of units",
    unitName: "Name unit set",
    save: "Save",
    Temp: "Temperature",
    Temp2: "Temperature Difference",
    Press: "Pressure",
    Volume: "Volume Flow",
    Pressure: "Pressure",
    Power: "Energy",
    Flow: "Mass Flow",
    CoolCapacity: "Cooling Capacity",
    HeatCapacity: "Heating Capacity",
    Velocity: "Velocity",
    VolumeFlow: "Volume Flow",
    MassFlow: "Mass Flow",
    Size: "Dimension",
    Density: "Density",
    Enthalpy: "Enthalpy",
    Entropy: "Entropy",
    FlowFactor: "Flow Factor",
  },
  document: {
    DataList: "Data sheet",
    Manual: "Manual",
    Photo: "Photo",
    DataLModelist: "3D Model",
  },
  notice: {
    title: "Recommended information",
    noShowAgain: "do not show again",
  },
  selection: {
    cancel: "Cancel",
    clear: "clean up",
    clearSelected: "Unselect All",
    deleteSelected: "Delete selection",
    removeAll: "Remove All",
    exportAll: "Export All",
  },
};
