const keys = [
  "Pos",
  "Dp1",
  "Dp2",
  "Dp1Unit",
  "Dp2Unit",
  "DpAlowIndex",
  "DpAlow",
  "DpAlowUnit",
  "DpAlowVisible",
  "CapicityIndex",
  "TkIndex",
  "TeIndex",
  "UnitId",
  "Refrigerant",
  "TeUnit",
  "TkUnit",
  "CapicityUnit",
  "DTSH1",
  "DTSC1",
  "DTSH1Unit",
  "DTSC1Unit",
  "DTSH2",
  "DTSC2",
  "DTSH2Unit",
  "DTSC2Unit",
  "Capicity",
  "Load",
  "LoadUnit",
  "Te",
  "Tk",
  "TexhaustgasIsChecked",
  "Texhaustgas",
  "TexhaustgasUnit",
  "NormalType",
  "WidthType",
  "FrequencyType",
  "EqualType",
  "Lnwd",
  "LnwdType",
];

export function buildConditionKey(condition) {
  let keyStr = "";
  keys.sort().forEach((key) => {
    const value = condition[key];

    if (value !== undefined && value !== null) {
      keyStr += `${key}=${value}&`;
    } else {
      keyStr += `${key}=&`;
    }
  });
  // 去除最后一个&
  keyStr = keyStr.slice(0, -1);
  return keyStr;
}
