<template>
  <div
    :class="isShrink ? 'selectionlist open' : 'selectionlist shrink'"
    v-show="list.length > 0"
  >
    <div class="sl">
      <div class="sl-item" v-for="(item, index) in list" :key="item.Hashkey">
        <div class="item-info">
          <el-image
            v-show="!isShrink"
            class="img"
            :src="
              item.imageUrl
                ? item.imageUrl.indexOf('http') !== -1
                  ? item.imageUrl
                  : Prefix + item.imageUrl
                : ''
            "
            fit="cover"
          />
          <div v-if="valveDic[item.ValveName]" class="title">
            {{ valveDic[item.ValveName].Name + " " + item.SerialName }}
          </div>
          <div class="name">{{ item.PartNumber || item.ProductName }}</div>
          <div v-show="!isShrink" class="showInfo" @click="showDetails(item)">
            {{ $t("sort.showInfo") }}
          </div>
          <el-checkbox
            v-show="clearStatu"
            v-model="item.checked"
            class="close-icon"
          ></el-checkbox>

          <i
            v-show="!clearStatu"
            @click="deleteItem(index)"
            class="close-icon el-icon-circle-close"
          ></i>
        </div>
        <div class="division"></div>
      </div>
    </div>
    <div class="menu" :class="isShrink ? 'shrink' : ''">
      <div class="total">
        <div v-show="isShrink" class="number">
          {{ `${$t("details.total")} ${list.length} ${$t("details.unit")}` }}
        </div>
        <div v-show="!isShrink" class="number">
          <div class="number-title">{{ $t("details.total") }}</div>
          <div class="number-number">
            {{ list.length }} <span>{{ $t("details.unit") }}</span>
          </div>
        </div>
        <div class="action" @click="shrink">
          <span v-show="isShrink"
            >{{ $t("selector.open") }}<i class="el-icon-arrow-up"></i
          ></span>
          <span v-show="!isShrink"
            >{{ $t("selector.putAway") }}<i class="el-icon-arrow-down"></i
          ></span>
        </div>
      </div>
      <div class="btn-box">
        <template v-if="!clearStatu">
          <el-button type="primary" size="mini" @click="checkExport">{{
            $t("info.export")
          }}</el-button>
          <el-button type="primary" size="mini" @click="saveSelection">{{
            $t("details.saveSelection")
          }}</el-button>
          <el-button type="primary" size="mini" @click="clearStatu = true">{{
            $t("selection.clear")
          }}</el-button>
        </template>
        <template v-else>
          <el-button type="primary" size="mini" @click="clearStatu = false">{{
            $t("selection.cancel")
          }}</el-button>
          <el-button type="danger" size="mini" @click="clearSelected">{{
            $t("selection.deleteSelected")
          }}</el-button>
          <el-button type="danger" size="mini" @click="clearSelection">{{
            $t("selection.removeAll")
          }}</el-button>
        </template>
        <!-- <template v-if="exportStatu">
          <el-button type="primary" size="mini" @click="exportStatu = false">{{ $t('selection.cancel') }}</el-button>
          <el-button type="primary" size="mini" @click="toExportSelected">{{ $t('info.export') }}</el-button>
          <el-button type="primary" size="mini" @click="toExport">{{ $t('selection.exportAll') }}</el-button>
        </template> -->
      </div>
    </div>
    <el-dialog
      :title="$t('message.selectExport')"
      :visible.sync="exportStatu"
      width="40%"
      append-to-body
      top="10vh"
    >
      <div class="export-list">
        <div
          class="export-list-item"
          v-for="productItem in list"
          :key="productItem.Hashkey"
        >
          <el-checkbox
            v-model="productItem.exportChecked"
            class="close-icon"
          ></el-checkbox>
          <el-image
            class="img"
            :src="
              productItem.imageUrl
                ? productItem.imageUrl.indexOf('http') !== -1
                  ? productItem.imageUrl
                  : Prefix + productItem.imageUrl
                : ''
            "
            fit="cover"
          ></el-image>
          <div v-if="valveDic[productItem.ValveName]" class="info">
            <div>
              {{
                valveDic[productItem.ValveName].Name +
                " " +
                productItem.SerialName
              }}
            </div>
            <div>{{ productItem.PartNumber || productItem.ProductName }}</div>
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="exportStatu = false">{{
          $t("selection.cancel")
        }}</el-button>
        <el-button type="primary" @click="clearExportSelected">{{
          $t("selection.clearSelected")
        }}</el-button>
        <el-button type="primary" @click="toExportSelected">{{
          $t("info.export")
        }}</el-button>
        <el-button type="primary" @click="toExport">{{
          $t("selection.exportAll")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "SelectionList",
  data() {
    return {
      isShrink: false,
      exportStatu: false,
      clearStatu: false,
      list: [],
    };
  },
  computed: {
    hiddenSelection: function () {
      return this.$store.state.app.hiddenSelection;
    },
    valveDic: function () {
      return this.$store.getters.valveDic;
    },
    productlist: function () {
      return this.$store.state.selection.list;
    },
    conditionlist: function () {
      return this.$store.state.selection.conditionList;
    },
    token: function () {
      return this.$store.getters.token;
    },
    language: function () {
      return this.$store.getters.language;
    },
  },
  watch: {
    list: {
      handler: function (val) {
        if (val.length === 0) {
          this.$store.dispatch("app/setHiddenSelection", true);
        } else {
          this.$store.dispatch("app/setHiddenSelection", false);
        }
      },
    },
    productlist: {
      handler: function (nVal) {
        this.list = nVal.map((item) => {
          return { ...item, checked: false, exportChecked: false };
        });
        this.$forceUpdate();
      },
    },
  },

  mounted() {
    this.filterProduct();
  },
  methods: {
    filterProduct() {
      this.list = this.productlist.map((item) => {
        return { ...item, checked: false, exportChecked: false };
      });
    },
    checkExport() {
      const { token } = this;
      if (token) {
        this.exportStatu = true;
      } else {
        return this.$message({
          message: this.$t("message.loginFirst"),
          type: "error",
        });
      }
    },
    toExportSelected() {
      // 导出选择
      const { token, list } = this;
      if (!token) {
        return this.$message({
          message: this.$t("message.loginFirst"),
          type: "error",
        });
      }
      const selectedProduct = list.filter((item) => item.exportChecked);
      if (selectedProduct.length === 0) {
        return this.$message({
          message: this.$t("message.selectExport"),
          type: "error",
        });
      }
      this.saveSelection().then(() => {
        const hashkeys = selectedProduct.map((item) => item.Hashkey);
        // this.$store.dispatch('selection/saveSelectedList', selectedProduct)
        const query = {
          selected: true,
          hashkeys: hashkeys.toString(),
          language: this.language,
        };
        const routeUrl = this.$router.resolve({ name: "Export", query });
        console.log(location, routeUrl);
        window.open(routeUrl.href, routeUrl.href);
      });
      // this.$confirm(this.$t('message.exportSelected'), this.$t('details.confirmTips'), {
      //   confirmButtonText: this.$t('form.confirm'),
      //   cancelButtonText: this.$t('form.cancel'),
      //   type: 'info',
      // })
      //   .then(() => {
      //     this.saveSelection().then(() => {
      //       const exportPartNumberlist = selectedProduct.map((item) => item.PartNumber)
      //       // this.$store.dispatch('selection/saveSelectedList', selectedProduct)
      //       const query = { selected: true, partNumbers: exportPartNumberlist.toString() }
      //       const routeUrl = this.$router.resolve({ name: 'Export', query })
      //       console.log(location, routeUrl)
      //       window.open(routeUrl.href, routeUrl.href)
      //     })
      //   })
      //   .catch(() => {})
    },
    shrink() {
      this.isShrink = !this.isShrink;
      this.$store.dispatch("app/setSelectionShrink", this.isShrink);
    },
    showDetails(product) {
      if (
        this.token === "" ||
        this.token === null ||
        this.token === undefined
      ) {
        return this.$message({
          message: this.$t("message.loginFirst"),
          type: "info",
        });
      }
      this.saveSelection();
      const PartNumber = product.PartNumber || product.ProductName;
      const Hashkey = product.Hashkey;
      this.$router.push({ name: "details", query: { PartNumber, Hashkey } });
    },
    async toExport() {
      if (!this.token) {
        return this.$message({
          message: this.$t("message.loginFirst"),
          type: "error",
        });
      }
      if (this.list.length > 0) {
        this.saveSelection().then(() => {
          const routeUrl = this.$router.resolve({
            name: "Export",
            query: { language: this.language },
          });
          window.open(routeUrl.href, routeUrl.href);
        });
        // this.$confirm(this.$t('message.exportAll'), this.$t('details.confirmTips'), {
        //   confirmButtonText: this.$t('form.confirm'),
        //   cancelButtonText: this.$t('form.cancel'),
        //   type: 'info',
        // })
        //   .then(() => {
        //     this.saveSelection().then(() => {
        //       const routeUrl = this.$router.resolve({ name: 'Export' })

        //       window.open(routeUrl.href, routeUrl.href)
        //       // this.$router.push({ name: 'Export' })
        //     })
        //   })
        //   .catch(() => {})
      } else {
        return this.$message({
          message: this.$t("message.noSelection"),
          type: "error",
        });
      }
    },
    saveSelection(noMessage) {
      if (
        this.token === "" ||
        this.token === null ||
        this.token === undefined
      ) {
        return this.$message({
          message: this.$t("message.loginFirst"),
          type: "info",
        });
      }

      return this.$store.dispatch("selection/saveUserSelection").then(() => {
        if (noMessage !== true) {
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
        }
      });
    },
    clearSelected() {
      const { list } = this;
      const selectedlist = list
        .reduce((arr, item, idx) => {
          if (item.checked) arr.push(idx);
          return arr;
        }, [])
        .reverse();
      if (selectedlist.length === 0) {
        return false;
      }
      this.$confirm(
        this.$t("message.clearSelected"),
        this.$t("details.confirmTips"),
        {
          confirmButtonText: this.$t("form.confirm"),
          cancelButtonText: this.$t("form.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          let flag = 0;
          selectedlist.forEach((index) => {
            flag++;
            this.$store.dispatch("selection/deleteProduct", index);
            if (flag === selectedlist.length) {
              this.$message({
                type: "success",
                message: this.$t("message.deleteSuccess"),
              });
              if (!this.$utils.isEmpty(this.token)) {
                this.saveSelection(true);
              }
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("message.cancelDelete"),
          });
        });
    },
    clearExportSelected() {
      this.list.forEach((item) => {
        item.exportChecked = false;
      });
    },
    clearSelection() {
      this.$confirm(
        this.$t("message.clearSelection"),
        this.$t("details.confirmTips"),
        {
          confirmButtonText: this.$t("form.confirm"),
          cancelButtonText: this.$t("form.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.$store.dispatch("selection/clearSelection", true);
          this.$message({
            type: "success",
            message: this.$t("message.deleteSuccess"),
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("message.cancelDelete"),
          });
        });
    },
    deleteItem(index) {
      this.$confirm(
        this.$t("message.deleteItem"),
        this.$t("details.confirmTips"),
        {
          confirmButtonText: this.$t("form.confirm"),
          cancelButtonText: this.$t("form.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.$store.dispatch("selection/deleteProduct", index);
          if (!this.$utils.isEmpty(this.token)) {
            this.saveSelection(true);
          }
          this.$message({
            type: "success",
            message: this.$t("message.deleteSuccess"),
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("message.cancelDelete"),
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.selectionlist {
  width: 100%;
  margin-top: 60px;
  display: flex;
  z-index: 9999;
  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);

  & .open {
    height: 174px;
  }
  & .shrink {
    height: 74px;
  }
  .sl {
    flex: 1;
    width: 90%;
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;

    .sl-item {
      width: 199px;
      flex-shrink: 0;
      position: relative;

      .close-icon {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .item-info {
        width: 198px;
        display: inline-block;
        padding: 10px;
        text-align: center;

        .img {
          width: 50px;
          height: 50px;
          margin: 10px auto;
        }
        .title {
          color: #999999;
        }
        .name {
          margin: 5px 0;
        }
        .showInfo {
          cursor: pointer;
          color: #1c69d4;
          margin-top: 21px;
        }
      }
      .division {
        display: inline-block;
        vertical-align: top;
        width: 1px;
        height: 90%;
        margin: 5% 0;
        background-image: linear-gradient(180deg, #ffffff, #d1d1d1, #ffffff);
      }
    }
  }
  .menu {
    padding: 15px;

    .total {
      text-align: center;
      padding-bottom: 10px;
      display: flex;

      .number {
        flex: 1;
        &-title {
          font-size: 24px;
        }
        &-number {
          font-size: 60px;
        }
        span {
          font-size: 14px;
        }
      }
      .action {
        margin-left: 10px;
      }
    }
    &.shrink {
      padding: 5px 10px;
      .total {
        padding-bottom: 5px;
      }
    }
  }
}
.export {
  &-list {
    max-height: 60vh;
    overflow: auto;
    &-item {
      display: flex;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid #cecece;

      .img {
        margin-left: 10px;
        width: 80px;
        height: 80px;
      }
      .info {
        margin-left: 10px;
      }
    }
  }
}
</style>
