import { login, getInfo } from "@/api/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
// import { resetRouter } from '@/router'
import md5 from "js-md5";
import Cookies from "js-cookie";

const getDefaultState = () => {
  return {
    token: getToken(),
    name: "",
    avatar: "",
    showInfo: true,
    Profile: {},
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_PROFILE: (state, Profile) => {
    state.Profile = Profile;
  },
  SET_INFO_SHOW: (state, show) => {
    state.showInfo = show;
  },
};

const actions = {
  // user login
  async login({ commit, rootState }, userInfo) {
    try {
      const { username, password } = userInfo;
      const md5pwd = md5(password);
      let authPwd = "";
      const { simpleCode, complexCode } = rootState.app;
      const reg = new RegExp(
        "^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$",
        "g"
      );
      if (!simpleCode || !complexCode) {
        return Promise.reject("App No Init Place Refresh");
      }
      const isComplexCode = password.length > 20 || reg.test(password);
      if (isComplexCode) {
        authPwd = `SH${md5pwd}${complexCode}`;
      } else {
        authPwd = `SH${md5pwd}${simpleCode}`;
      }
      const loginResp = await login({
        username: username.trim(),
        password: authPwd,
      });

      const { Data } = loginResp;
      var { UserName, Photo, Remarks } = Data.Profile;
      const remarkJson = JSON.parse(Remarks);
      const { UnitId, Refrigerants, CustomUnitId } = remarkJson;
      if (UnitId) {
        commit("app/SET_UNIT_ID", UnitId, { root: true });
      }
      if (CustomUnitId) {
        commit("app/SET_CUSTOM_UNIT_ID", CustomUnitId, { root: true });
      }
      if (Refrigerants) {
        const splitList = Refrigerants.split(";");
        const Refrigerant = splitList[0];
        const collectList = splitList[1].split(",");
        commit("condition/SET_REFRIGERANT", Refrigerant, { root: true });
        commit("condition/SET_COLLECTION_REFRIGERANT_LIST", collectList, {
          root: true,
        });
      }
      if (!Photo) {
        Photo =
          "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif";
      }
      commit("SET_NAME", UserName);
      commit("SET_AVATAR", Photo);
      commit("SET_TOKEN", Data.Token);
      commit("SET_PROFILE", Data.Profile);
      setToken(Data.Token);
      return true;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then((response) => {
          const { Data } = response;
          if (!Data) {
            return reject("Verification failed, please Login again.");
          }
          var { UserName, Photo, Remarks } = Data.Profile;
          const remarkJson = JSON.parse(Remarks);
          const { UnitId, Refrigerants, CustomUnitId } = remarkJson;
          if (UnitId) {
            commit("app/SET_UNIT_ID", UnitId, { root: true });
          }
          if (CustomUnitId) {
            commit("app/SET_CUSTOM_UNIT_ID", CustomUnitId, { root: true });
          }
          if (Refrigerants) {
            const splitList = Refrigerants.split(";");
            const Refrigerant = splitList[0];
            const collectList = splitList[1].split(",");
            commit("condition/SET_REFRIGERANT", Refrigerant, { root: true });
            commit("condition/SET_COLLECTION_REFRIGERANT_LIST", collectList, {
              root: true,
            });
          }
          if (!Photo) {
            Photo =
              "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif";
          }
          commit("SET_NAME", UserName);
          commit("SET_AVATAR", Photo);
          commit("SET_TOKEN", Data.Token);
          commit("SET_PROFILE", Data.Profile);
          resolve(Data.Profile);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve) => {
      // logout(state.token).then(() => {
      removeToken(); // must remove  token  first
      Cookies.remove("Refrigerant");
      Cookies.remove("CollectionRefrigerantList");
      // resetRouter()
      commit("condition/SET_REFRIGERANT", "", { root: true });
      commit("condition/SET_COLLECTION_REFRIGERANT_LIST", [], { root: true });
      commit("RESET_STATE");
      resolve();
      // }).catch(error => {
      //   reject(error)
      // })
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken(); // must remove  token  first
      commit("RESET_STATE");
      resolve();
    });
  },

  setInfoShow({ commit }, show) {
    commit("SET_INFO_SHOW", show);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
