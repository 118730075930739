import { getCondition } from "@/api/valve";
import i18n from "@/lang";
const state = {
  field: {
    cn: {
      Sov: [],
      Mv4: [],
      Bv: [],
      Ssv: [],
      Eev: [],
      Txv: [],
      Sg: [],
      Filter: [],
      Cvs: [],
    },
    en: {
      Sov: [],
      Mv4: [],
      Bv: [],
      Ssv: [],
      Eev: [],
      Txv: [],
      Sg: [],
      Filter: [],
      Cvs: [],
    },
  },
  optionMap: {
    cn: {
      Sov: [],
      Mv4: [],
      Bv: [],
      Ssv: [],
    },
    en: {
      Sov: [],
      Mv4: [],
      Bv: [],
      Ssv: [],
    },
  },
};

const mutations = {
  SET_FIELD: (state, { ValveName, language, fieldList }) => {
    if (!state.field[language]) {
      state.field[language] = {};
    }
    if (!state.field[language][ValveName]) {
      state.field[language][ValveName] = [];
    }
    state.field[language][ValveName] = fieldList;
  },
  SET_OPTIONMAP: (state, { ValveName, language, optionMap }) => {
    if (!state.optionMap[language]) {
      state.optionMap[language] = {};
    }
    if (!state.optionMap[language][ValveName]) {
      state.optionMap[language][ValveName] = [];
    }
    state.optionMap[language][ValveName] = optionMap;
  },
};

const actions = {
  getFields({ commit, state, rootState }, { ValveName, Valveid, Serialid }) {
    const language = rootState.app.language;
    return new Promise((resolve, reject) => {
      if (
        state.field[language] &&
        state.field[language][ValveName] &&
        state.field[language][ValveName].length !== 0
      ) {
        const result = {
          fieldList: state.field[language][ValveName],
          optionMap: state.optionMap[language][ValveName],
        };
        resolve(result);
      } else {
        getCondition({ Valveid, Serialid })
          .then((res) => {
            const { serialResult } = res.Data;
            var fieldList = [];
            var optionMap = {};
            var flag = 0;

            serialResult.forEach((field) => {
              const fieldItem = { name: field.name, type: "default" };
              if (field.options && field.options.length > 1) {
                optionMap[`${field.name}Options`] = field.options.map(
                  (item) => {
                    fieldItem.type = "dic";
                    fieldItem.option = `${field.name}Options`;
                    return item.title;
                  }
                );
              } else if (field.options && field.options.length === 1) {
                fieldItem.label = field.options[0].title;
              } else if (
                [
                  "NormalType",
                  "EqualType",
                  "WidthType",
                  "FrequencyType",
                ].includes(field.name)
              ) {
                optionMap[`${field.name}Options`] =
                  ValveName === "TxvOld"
                    ? field.values.reverse()
                    : field.values;
                fieldItem.type = "valueDic";
                fieldItem.option = `${field.name}Options`;
                fieldItem.label =
                  (i18n.messages[language] &&
                    i18n.messages[language].selector &&
                    i18n.messages[language].selector[field.name]) ||
                  i18n.messages.en.selector[field.name] ||
                  field.name;
              } else if (["Refrigerant"].includes(field.name)) {
                fieldItem.label =
                  (i18n.messages[language] &&
                    i18n.messages[language].selector &&
                    i18n.messages[language].selector[field.name]) ||
                  i18n.messages.en.selector[field.name];
              }
              fieldList.push(fieldItem);
              flag++;
            });

            if (flag === serialResult.length) {
              fieldList = fieldList.filter(
                (field) => !["Pos"].includes(field.name)
              );
              commit("SET_FIELD", { ValveName, language, fieldList });
              commit("SET_OPTIONMAP", { ValveName, language, optionMap });
              resolve({ fieldList, optionMap });
            }
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
