<template>
  <div class="home">
    <Header />
    <div class="main">
      <div class="unit">{{ $t("home.title") }}</div>
      <div class="slogan">
        <span>{{ $t(`home.tips`) }}</span>
      </div>
    </div>
    <div class="posCenter">
      <div class="menu">
        <div class="refrigerant">
          {{ $t("home.Refrigerant") }}
          <div class="selectBox">
            <div
              class="selectRefrigerant"
              @click="refrigerantSelectShow = true"
            >
              <div class="tipsText">
                {{ Refrigerant || $t("home.RefrigerantSelect") }}
              </div>
              <i class="selecticon el-icon-arrow-right"></i>
            </div>
            <div class="selectSafetycer" @click="safetycerShow = true">
              <div class="tipsText">
                {{ safetycerSelect.toString() || $t("home.Safetycer") }}
              </div>
              <i class="selecticon el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
        <div class="sortTab">
          <div
            class="tab"
            :class="sortIndex === 1 ? 'isSelected' : ''"
            @click="
              sortIndex = 1;
              systemid = null;
              systemIndex = 0;
            "
          >
            {{ $t("home.ProductCategory") }}
          </div>
          <div
            class="tab"
            :class="sortIndex === 2 ? 'isSelected' : ''"
            @click="
              sortIndex = 2;
              systemIndex = 0;
            "
          >
            {{ $t("home.Application") }}
          </div>
        </div>
        <div class="type" v-show="sortIndex === 2">
          <span>{{ $t("home.systemType") }}</span>
          <el-select v-model="systemType" clearable>
            <el-option
              v-for="option in systemTypeList"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="valve" v-show="!systemid && sortIndex === 1">
        <div
          class="valveItem"
          v-for="(valve, index) in valveList.slice(
            systemIndex * 9,
            (systemIndex + 1) * 9
          )"
          :class="index % 5 !== 0 ? 'divider' : ''"
          :key="valve.valveName"
          @click="goInfo(valve)"
        >
          <el-image class="valveImg" :src="valve.imgUrl" fit="cover"></el-image>
          <p>{{ valve.Name }}</p>
        </div>
        <div class="valveItem page">
          <div class="pageBox">
            <div class="btn" v-show="systemIndex > 0" @click="systemIndex--">
              <i class="el-icon-arrow-left"></i>
              <span>{{ $t("home.prePage") }}</span>
            </div>
            <div
              class="btn"
              v-show="(systemIndex + 1) * 9 < valveList.length"
              @click="systemIndex++"
            >
              <span>{{ $t("home.nextPage") }}</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="system" v-show="!systemid && sortIndex === 2">
        <div
          class="systemItem"
          v-for="(system, index) in valveSystemShowList.slice(
            systemIndex * 9,
            (systemIndex + 1) * 9
          )"
          :key="system.Id"
          :class="index % 5 !== 0 ? 'divider' : ''"
          @click="getSystemSeries(system.Id)"
        >
          <div class="imageBox">
            <el-image
              class="systemImg"
              :src="system.ImgUrl"
              fit="contain"
            ></el-image>
          </div>
          <p class="systemName">{{ system.Name }}</p>
        </div>
        <div class="systemItem page">
          <div class="pageBox">
            <div class="btn" v-show="systemIndex > 0" @click="systemIndex--">
              <i class="el-icon-arrow-left"></i>
              <span>{{ $t("home.prePage") }}</span>
            </div>
            <div
              class="btn"
              v-show="(systemIndex + 1) * 9 < valveSystemShowList.length"
              @click="systemIndex++"
            >
              <span>{{ $t("home.nextPage") }}</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="systemValve" v-if="systemid">
        <div class="systemInfo">
          <div class="imageBox" @click="systemid = null">
            <el-image
              class="systemImg"
              :src="valveSystemDic[systemid].ImgUrl"
              fit="contain"
            ></el-image>
          </div>
          <p class="systemName">{{ valveSystemDic[systemid].Name }}</p>
          <el-button
            type="primary"
            class="buttom"
            icon="el-icon-arrow-left"
            @click="systemid = null"
            >{{ $t("home.Reselect") }}</el-button
          >
        </div>
        <div class="valvebox" ref="serialBox">
          <div
            class="valveItem"
            @click="goInfo(null, serial)"
            :class="index % 4 !== 0 ? 'divider' : ''"
            :key="`${serial.valveName}-${index}`"
            v-for="(serial, index) in serialList.slice(
              systemValveIndex * 7,
              (systemValveIndex + 1) * 7
            )"
          >
            <el-image
              class="valveImg"
              :src="serial.imgUrl"
              fit="cover"
            ></el-image>
            <p>{{ serial.Name }}</p>
            <p>{{ valveDic[serial.Valvename].Name }}</p>
          </div>
          <div class="valveItem page">
            <div class="pageBox">
              <div
                class="btn"
                v-show="systemValveIndex > 0"
                @click="systemValveIndex--"
              >
                <i class="el-icon-arrow-left"></i>
                <span>{{ $t("home.prePage") }}</span>
              </div>
              <div
                class="btn"
                v-show="(systemValveIndex + 1) * 7 < serialList.length"
                @click="systemValveIndex++"
              >
                <span>{{ $t("home.nextPage") }}</span>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="recordNo">
      <a
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33062402000765"
        target="_blank"
        ><img width="20px" height="20px" src="@/assets/icons/gwab.png" />{{
          $t("home.gawRecordNo")
        }}</a
      >
      <a href="https://beian.miit.gov.cn" target="_blank">{{
        $t("home.recordNo")
      }}</a>
    </div>
    <el-dialog
      :title="$t('home.RefrigerantSelect')"
      :visible.sync="refrigerantSelectShow"
      top="5vh"
      width="45%"
    >
      <div class="RefDialog">
        <div class="Refselect">
          <el-input
            :placeholder="$t('form.pleaseSelect')"
            prefix-icon="el-icon-search"
            v-model="Refkeyword"
            @input="searchRefrigerant"
          />
          <div
            class="refrigerantItem"
            v-for="refrigerant in refrigerantList"
            :key="refrigerant.value"
          >
            <div
              class="refrigerantName"
              @click="refrigerantSelect(refrigerant.value)"
              :class="
                refrigerantSelected === refrigerant.value ? 'isAction' : ''
              "
            >
              {{ refrigerant.value }}
            </div>
            <div
              class="collect"
              @click="collectRefrigerant(refrigerant.value)"
              :class="
                refrigerantCollectList.includes(refrigerant.value)
                  ? 'isCollect'
                  : ''
              "
            >
              <i class="collectIcon el-icon-star-on"></i
              >{{ $t("home.Collection") }}
            </div>
          </div>
        </div>
        <div class="Refcollect">
          <div>{{ $t("home.myCollection") }}</div>
          <el-empty
            v-if="refrigerantCollectList.length === 0"
            :description="$t('home.noCollection')"
          ></el-empty>
          <div
            class="refrigerantCollect"
            v-for="refrigerant in refrigerantCollectList"
            :key="refrigerant"
          >
            <div
              class="refrigerantName"
              :class="refrigerantSelected === refrigerant ? 'isAction' : ''"
              @click="refrigerantSelect(refrigerant)"
            >
              {{ refrigerant }}
            </div>
            <div class="delete" @click="deleteCollect(refrigerant)">
              <i class="deleteIcon el-icon-delete-solid"></i
              >{{ $t("home.delete") }}
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refrigerantSelectShow = false">
          {{ $t("home.cancel") }}
        </el-button>
        <el-button type="warning" @click="deleteDefaultRefrigerant">
          {{ $t("home.noSetting") }}
        </el-button>
        <el-button type="primary" @click="saveDefaultRefrigerant">
          {{ $t("home.confirm") }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('home.Safetycer')"
      :visible.sync="safetycerShow"
      top="5vh"
      width="30%"
    >
      <div class="safetycer-content">
        <div
          class="safetycer-item"
          v-for="safetycer in safetycerlist"
          :key="safetycer.value"
          :class="currentSafetycer.includes(safetycer.value) ? 'isAction' : ''"
          @click="selectSafetycer(safetycer.value)"
        >
          {{ safetycer.label }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="safetycerShow = false">{{
          $t("home.cancel")
        }}</el-button>

        <el-button type="warning" @click="deleteSafetycer">{{
          $t("home.noSetting")
        }}</el-button>
        <el-button type="primary" @click="selectAllSafetycer">{{
          $t("home.selectAll")
        }}</el-button>
        <el-button type="primary" @click="saveSafetycerSelect">{{
          $t("home.confirm")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import { mapState, mapGetters } from "vuex";
/**
 * @param {Number} sortIndex 切换展示tab
 * @param {Boolean} refrigerantSelectShow 选择制冷剂展示状态
 * @param {String} Refkeyword 制冷剂搜索关键词
 * @param {Number} systemIndex 展示系统位置
 * @param {Array} systemValveList 系统阀门列表
 * @param {Number} systemValveIndex 展示系统阀门位置
 * @param {Array} refrigerantList 制冷剂列表
 * @param {String} refrigerantSelected 选中制冷剂
 * @param {Array} refrigerantCollectList 收藏制冷剂列表
 * @param {String} systemType 系统分类类型
 * @param {Array} valveSystemShowList 阀门系统展示列表
 * @param {Array} systemTypeList 系统分类列表
 * @param {Array} serialList 系统系列列表
 * @param {Boolean} loading 加载状态
 */
export default {
  name: "HomeIndex",
  components: { Header },
  data() {
    return {
      sortIndex: 1,
      refrigerantSelectShow: false,
      Refkeyword: "",
      systemList: [],
      systemid: null,
      systemIndex: 0,
      systemValveList: [],
      systemValveIndex: 0,
      refrigerantSelected: "",
      systemType: "",
      valveSystemShowList: [],
      serialList: [],
      loading: false,
      safetycerSelect: [],
      currentSafetycer: [],
      safetycerShow: false,
      safetycerlist: [
        { label: "CE", value: "CE" },
        { label: "LVD", value: "LVD" },
        { label: "LVD(TUV/VDE)", value: "TUV,VDE" },
        { label: "A2L/A3", value: "A2L/A3" },
        { label: "PED II", value: "PED II" },
        { label: "PED IV", value: "PED IV" },
        { label: "UKCA", value: "UKCA" },
        { label: "UL", value: "UL" },
        { label: "CUL", value: "CUL" },
        { label: "CRN", value: "CRN" },
      ],
    };
  },
  computed: {
    ...mapState({
      valveList: (state) => state.valve.valveBaseInfo,
      Refrigerant: (state) => state.condition.Refrigerant,
      lang: (state) => state.app.language,
      token: (state) => state.user.token,
      userName: (state) => state.user.name,
      refrigerantCollectList: (state) =>
        state.condition.CollectionRefrigerantList,
    }),
    ...mapGetters({
      valveSystemList: "valveSystem",
      valveSystemDic: "valveSystemDic",
      systemTypeList: "valveSystemSort",
      valveDic: "valveDic",
      refrigerantList: "refrigerantList",
      storeSafetycerSelect: "safetycerSelect",
    }),
  },
  watch: {
    valveSystemList: {
      handler: function () {
        if (!this.$utils.isEmpty(this.systemType)) {
          this.valveSystemShowList = this.valveSystemList.filter(
            (system) => system.Sort === this.systemType
          );
        } else {
          this.valveSystemShowList = this.valveSystemList;
        }
      },
    },
    systemType: {
      immediate: true,
      handler: function (nVal) {
        this.systemIndex = 0;
        if (!this.$utils.isEmpty(nVal)) {
          this.valveSystemShowList = this.valveSystemList.filter(
            (system) => system.Sort === nVal
          );
        } else {
          this.valveSystemShowList = this.valveSystemList;
        }
      },
    },
    Refrigerant: {
      handler: function (nVal) {
        this.refrigerantSelected = nVal;
      },
    },
    storeSafetycerSelect: {
      immediate: true,
      handler: function (nVal) {
        console.log(nVal);
        this.safetycerSelect = nVal;
        this.currentSafetycer = nVal;
      },
    },
  },
  created() {
    this.refrigerantSelected = this.Refrigerant;
    const query = this.$route.query;
    if (query.systemId) {
      this.sortIndex = 2;
    }
  },

  methods: {
    goInfo(valve, serial) {
      // if (
      //   (valve && ['Txv'].includes(valve.valveName)) ||
      //   (serial && ['Txv'].includes(serial.Valvename))
      // ) {
      //   return this.$message({
      //     message: this.$t('message.noData'),
      //     type: 'info'
      //   })
      // }
      const { valveDic } = this;
      const nowValve = valve ? valve : valveDic[serial.Valvename];
      if (nowValve.CalculateType === 0) {
        return this.$router.push({
          name: "sort",
          query: { valveName: nowValve.valveName },
        });
      }

      if (valve) {
        this.$router.push({
          name: "Selector",
          query: { valveName: valve.valveName, systemId: this.systemid },
        });
      } else {
        this.$router.push({
          name: "Selector",
          query: { systemId: this.systemid, serialId: serial.Id },
        });
      }
    },
    selectSafetycer(safetycer) {
      const index = this.currentSafetycer.findIndex(
        (item) => item === safetycer
      );
      if (index !== -1) {
        this.currentSafetycer.splice(index, 1);
        return;
      }
      this.currentSafetycer.push(safetycer);
    },
    deleteSafetycer() {
      this.currentSafetycer = [];
      this.safetycerSelect = [];
      this.safetycerShow = false;
      this.$store.dispatch("condition/setSafetycer", []);
      return this.$message({
        message: this.$t("message.saveSuccess"),
        type: "success",
      });
    },
    selectAllSafetycer() {
      const { safetycerlist } = this;
      this.currentSafetycer = safetycerlist.map((item) => item.value);
      this.safetycerSelect = safetycerlist.map((item) => item.value);
      this.safetycerShow = false;
      this.$store.dispatch("condition/setSafetycer", this.currentSafetycer);
      return this.$message({
        message: this.$t("message.saveSuccess"),
        type: "success",
      });
    },
    saveSafetycerSelect() {
      if (this.currentSafetycer.length === 0) {
        return this.$message({
          message: this.$t("form.pleaseSelect"),
          type: "error",
        });
      }
      this.safetycerSelect = this.currentSafetycer.map((item) => item);
      this.$store.dispatch("condition/setSafetycer", this.safetycerSelect);
      this.safetycerShow = false;
      return this.$message({
        message: this.$t("message.saveSuccess"),
        type: "success",
      });
    },
    //获取阀门系统系列数据
    getSystemSeries(systemId) {
      console.log(this.valveSystemDic[systemId]);
      const system = this.valveSystemDic[systemId];
      if (system.ValveIdList.length === 0) {
        return this.$message({
          message: this.$t("message.noData"),
          type: "info",
        });
      }
      this.$nextTick(() => {
        const dom = this.$refs.serialBox;
        const loading = this.$loading({
          lock: true,
          target: dom,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let params = {
          systemId: systemId,
        };
        this.systemid = systemId;
        this.$store
          .dispatch("serial/getSystemSerial", params)
          .then((res) => {
            this.serialList = res;
            loading.close();
          })
          .catch(() => {
            loading.close();
          });
      });
    },
    showValve(system) {
      this.systemid = system.Id;
      this.systemValveList = this.valveList.filter((valve) =>
        system.ValveIdList.includes(valve.Id)
      );
    },
    retract() {
      this.systemid = null;
      this.systemChildrenkey = null;
      this.systemValveList = [];
    },
    navPage(key) {
      switch (key) {
        case "left":
          if (!this.systemid) {
            this.systemIndex--;
          } else {
            this.systemValveIndex--;
          }
          break;
        case "right":
          if (!this.systemid) {
            this.systemIndex++;
          } else {
            this.systemValveIndex++;
          }
          break;
        default:
          break;
      }
    },
    refrigerantSelect(refrigerant) {
      this.refrigerantSelected = refrigerant;
    },
    deleteCollect(refrigerant) {
      const refrigerantCollectList = this.refrigerantCollectList.filter(
        (item) => item !== refrigerant
      );
      this.$store.dispatch(
        "condition/setCollectionList",
        refrigerantCollectList
      );
    },
    collectRefrigerant(refrigerant) {
      const hasRefrigerant = this.refrigerantCollectList.some(
        (item) => item === refrigerant
      );
      if (!hasRefrigerant) {
        this.refrigerantCollectList.push(refrigerant);
        this.$store.dispatch(
          "condition/setCollectionList",
          this.refrigerantCollectList
        );
      } else {
        this.deleteCollect(refrigerant);
      }
    },

    deleteDefaultRefrigerant() {
      this.$store.dispatch("condition/setDefaultRefrigerant", "");
      this.refrigerantSelected = "";
      this.refrigerantSelectShow = false;
      return this.$message({
        message: this.$t("message.saveSuccess"),
        type: "success",
      });
    },
    saveDefaultRefrigerant() {
      this.$store.dispatch(
        "condition/setDefaultRefrigerant",
        this.refrigerantSelected
      );
      this.refrigerantSelectShow = false;
      return this.$message({
        message: this.$t("message.saveSuccess"),
        type: "success",
      });
    },
    searchRefrigerant(key) {
      const { refrigerantList } = this;
      this.refrigerantList = refrigerantList.filter(
        (item) => item.value.indexOf(key) !== -1
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  // height: 100%;
  min-height: 100%;
  padding-top: 20px;
  // overflow: hidden;
  position: relative;
  background-image: url("../../assets/images/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .recordNo {
    width: 80%;
    margin: 0 auto;
    color: rgba($color: #ffffff, $alpha: 0.5);
    // position: absolute;
    font-size: 14px;
    margin-top: 20px;
    padding-bottom: 10px;
    // bottom: 2%;
    // left: 10%;
    user-select: none;
    a + a {
      margin-left: 20px;
    }
    a {
      color: rgba($color: #ffffff, $alpha: 0.5);
      text-decoration: none;
    }
  }

  // .posCenter {
  //   width: 100%;
  //   margin-top: 20px;
  //   position: absolute;
  //   left: 50%;
  //   top: 55%;
  //   transform: translate(-50%, -50%);
  // }
  .main {
    width: 80%;
    margin: 0 auto;
    color: #ffffff;
    margin-top: 20px;
    .unit {
      font-size: 48px;
    }
    .slogan {
      color: #a9afc3;
      font-size: 16px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      span + span {
        margin-left: 5px;
      }
    }
  }
  .menu {
    width: 80%;
    margin: 0 auto;
    margin-top: 2%;
    align-items: center;
    display: flex;
    .refrigerant {
      color: #ffffff;
      display: flex;
      margin-right: 20px;
      align-items: center;
      cursor: pointer;
      .selectBox {
        margin-left: 10px;
        display: flex;
        align-items: center;
        width: 280px;
        height: 40px;
        line-height: 20px;
        border: 1px solid #ffffff;
        border-radius: 5px;
        background: rgba($color: #ffffff, $alpha: 0.15);
        opacity: 0.5;

        .selectRefrigerant {
          width: 55%;
          height: 100%;
          padding: 10px 15px;
          text-overflow: ellipsis;
          display: flex;
          align-items: center;
        }
        .selectSafetycer {
          border-left: 1px solid #ffffff;
          padding: 10px 15px;
          height: 100%;
          width: 45%;
          display: flex;
          align-items: center;
        }
        .tipsText {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          -webkit-line-clamp: 1;
          flex: 1;
          color: #ffffff;
        }
      }
      .selecticon {
        float: right;
      }
    }
    .sortTab {
      vertical-align: top;
      float: right;
      display: flex;

      .tab {
        color: #ffffff;
        position: relative;
        cursor: pointer;

        &::after {
          content: " ";
          position: absolute;
          bottom: -5px;
          left: 0;
          background-color: #ffffff;
          width: 100%;
        }
        & + .tab {
          margin-left: 20px;
        }
        &.isSelected {
          color: #ffd300;
        }
        &.isSelected::after {
          height: 1px;
          background-color: #ffd300;
        }
      }
    }
    .type {
      margin-left: auto;
      color: #ffffff;
    }
  }
  .valve {
    width: 80%;
    height: 570px;
    overflow: auto;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    background: rgba($color: #018ebd, $alpha: 0.15);
    .valveItem {
      width: 20%;
      height: calc(50% - 60px);
      vertical-align: top;
      text-align: center;
      padding: 10px;
      color: #ffffff;
      position: relative;
      margin: 30px 0;
      cursor: pointer;

      .valveImg {
        transition: 300ms;
        width: 150px;
        height: 150px;
      }
      &:hover {
        .valveImg {
          transform: scale(1.2);
        }
      }
      &.divider::after {
        content: " ";
        position: absolute;
        width: 1px;
        height: 60%;
        background: rgba($color: #ffffff, $alpha: 0.3);
        top: 20%;
        left: 0;
      }
      &.page {
        align-self: flex-end;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        cursor: auto;
        margin-left: auto;
        .pageBox {
          .btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 5px 10px;
            border: 1px solid #fff;
            span {
              margin: 0 10px;
            }
            & + .btn {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }

  .system {
    width: 80%;
    height: 570px;
    overflow: auto;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    background: rgba($color: #018ebd, $alpha: 0.15);
    .systemItem {
      width: 20%;
      height: calc(50% - 60px);
      vertical-align: top;
      text-align: center;
      padding: 10px;
      color: #ffffff;
      position: relative;
      margin: 30px 0;
      cursor: pointer;
      .imageBox {
        width: 100px;
        height: 100px;
        border-radius: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        background-color: #fff;
        .systemImg {
          transition: 300ms;
          width: 60px;
          height: 60px;
        }
      }
      .systemName {
        margin-top: 30px;
      }
      &:hover {
        .systemImg {
          transform: scale(1.2);
        }
      }
      &.page {
        align-self: flex-end;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        cursor: auto;
        margin-left: auto;
        .pageBox {
          .btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 5px 10px;
            border: 1px solid #fff;
            span {
              margin: 0 10px;
            }
            & + .btn {
              margin-top: 20px;
            }
          }
        }
      }
      &.divider::after {
        content: " ";
        position: absolute;
        width: 1px;
        height: 60%;
        background: rgba($color: #ffffff, $alpha: 0.3);
        top: 20%;
        left: 0;
      }
    }
  }

  .systemValve {
    width: 80%;
    margin: 0 auto;
    display: flex;
    height: 570px;
    margin-top: 20px;
    .systemInfo {
      width: 20%;
      height: 100%;
      text-align: center;

      .imageBox {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 30px;
        background-color: #fff;
        border-radius: 500px;
        .systemImg {
          width: 60px;
          height: 60px;
        }
      }
      .buttom {
        margin-top: 120px;
        border: 1px solid rgba($color: #ffffff, $alpha: 0.5);
      }
      .systemName {
        margin-top: 40px;
        color: #ffffff;
      }
    }
    .valvebox {
      width: 80%;
      background: linear-gradient(180deg, #003040 0%, rgba(0, 48, 64, 0) 100%);
      display: flex;
      flex-wrap: wrap;
      .valveItem {
        width: 25%;
        height: calc(50% - 30px);
        vertical-align: top;
        text-align: center;
        padding: 10px;
        color: #ffffff;
        position: relative;
        margin: 20px 0;
        cursor: pointer;
        .valveImg {
          transition: 300ms;
          width: 150px;
          height: 150px;
        }
        &:hover {
          .valveImg {
            transform: scale(1.2);
          }
        }
        &.divider::after {
          content: " ";
          position: absolute;
          width: 1px;
          height: 60%;
          background: rgba($color: #ffffff, $alpha: 0.3);
          top: 20%;
          left: 0;
        }
        &.page {
          align-self: flex-end;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
          cursor: auto;
          margin-left: auto;
          .pageBox {
            .btn {
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              padding: 5px 10px;
              border: 1px solid #fff;
              span {
                margin: 0 10px;
              }
              & + .btn {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }

  .RefDialog {
    .Refselect {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      padding: 20px;
      height: 500px;
      overflow: auto;

      .refrigerantItem {
        margin-top: 14px;
        display: flex;
        align-items: center;
        .refrigerantName {
          cursor: pointer;
          transition: 300ms;
          width: 70%;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid rgba($color: #000000, $alpha: 0.15);
          &.isAction {
            background: rgba(0, 116, 155, 0.2);
          }
        }
        .collect {
          margin-left: 10px;
          cursor: pointer;
          .collectIcon {
            margin-right: 5px;
          }
          &.isCollect {
            color: #333333;
            .collectIcon {
              color: #ffd300;
            }
          }
        }
      }
    }
    .Refcollect {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      padding: 20px;
      height: 500px;
      overflow: auto;
      .refrigerantCollect {
        margin-top: 14px;
        display: flex;
        align-items: center;
        .refrigerantName {
          width: 73%;
          padding: 10px;
          border-radius: 5px;
          background: #f8f8f8;
          cursor: pointer;
          &.isAction {
            background: rgba(0, 116, 155, 0.2);
          }
        }
        .delete {
          margin-left: 10px;
          cursor: pointer;
          .deleteIcon {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
.safetycer {
  &-content {
    width: 100%;
    padding: 10px;
    overflow: auto;
    max-height: 80vh;
  }
  &-item {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    transition: 300ms;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid rgba($color: #000000, $alpha: 0.15);
    & + & {
      margin-top: 15px;
    }
    &.isAction {
      background: rgba(0, 116, 155, 0.2);
    }
  }
}
</style>
<style lang="scss">
.home {
  .el-select .el-input__inner {
    background: rgba($color: #ffffff, $alpha: 0.15);
    color: #ffffff;
    border: 1px solid rgba($color: #ffffff, $alpha: 0.5);
  }
}
</style>
