import {
  getValve,
  getCondition,
  getBaseValve,
  getSerialField,
  getSerialList,
} from "@/api/valve";
import Cookies from "js-cookie";
const ImgUrl = process.env.VUE_APP_IMAGE_URL;
const state = {
  valveBaseInfo: [],
  valveDic: {},
  valveSystem: [],
  valveSystemDic: {},
  valveSystemSort: [],
  valveSerialDic: {},
  serialSizeDic: {},
  refrigerantList: [],
  ConditionDic: {
    cn: {
      unit1: {},
      unit2: {},
    },
    en: {
      unit1: {},
      unit2: {},
    },
  },
  serialFieldMap: {},
};

const mutations = {
  SET_VALVE_BASE_INFO: (state, baseInfo) => {
    state.valveBaseInfo = baseInfo;
  },
  // SET_VALVE_INFO: (state, data) => {
  //   const { valveId, info } = data
  //   if (state.valveInfo[valveId] !== undefined) {
  //     Object.assign(state.valveInfo[valveId], info)
  //   } else {
  //     state.valveInfo[valveId] = info
  //   }
  // },
  SET_VALVE_DIC: (state, data) => {
    state.valveDic = data;
  },
  SET_SERIAL_SIZE_DIC: (state, data) => {
    const { language, serialName, type, result } = data;
    if (!state.serialSizeDic[language]) {
      state.serialSizeDic[language] = {};
    }
    if (!state.serialSizeDic[language][serialName]) {
      state.serialSizeDic[language][serialName] = {};
    }
    if (!state.serialSizeDic[language][serialName][type]) {
      state.serialSizeDic[language][serialName][type] = "";
    }
    state.serialSizeDic[language][serialName][type] = JSON.stringify(result);
  },
  SET_CONDITION_DIC: (state, data) => {
    const { language, UnitId, valveName, serialId, result } = data;
    if (!state.ConditionDic[language]) {
      state.ConditionDic[language] = {};
    }
    if (!state.ConditionDic[language][`unit${UnitId}`]) {
      state.ConditionDic[language][`unit${UnitId}`] = {};
    }
    if (
      !state.ConditionDic[language][`unit${UnitId}`][`${valveName}-${serialId}`]
    ) {
      state.ConditionDic[language][`unit${UnitId}`][
        `${valveName}-${serialId}`
      ] = "";
    }
    state.ConditionDic[language][`unit${UnitId}`][`${valveName}-${serialId}`] =
      JSON.stringify(result);
  },
  SET_VALVE_SYSTEM: (state, valveSystem) => {
    state.valveSystem = valveSystem;
  },
  SET_VALVE_SYSTEM_DIC: (state, valveSystemDic) => {
    state.valveSystemDic = valveSystemDic;
  },
  SET_VALVE_SYSTEM_SORT: (state, valveSystemSort) => {
    state.valveSystemSort = valveSystemSort;
  },
  SET_VALVE_SERIAL_DIC: (state, options) => {
    const { valveName, language, UnitId, serialList } = options;
    if (!state.valveSerialDic[language]) {
      state.valveSerialDic[language] = {};
    }
    if (!state.valveSerialDic[language][UnitId]) {
      state.valveSerialDic[language][UnitId] = {};
    }
    if (!state.valveSerialDic[language][UnitId][valveName]) {
      state.valveSerialDic[language][UnitId][valveName] = [];
    }
    state.valveSerialDic[language][UnitId][valveName] = serialList;
  },
  SET_REFRIGERANT_LIST: (state, list) => {
    state.refrigerantList = list;
  },
  SET_SERIAL_FIELDS: (state, { key, list }) => {
    state.serialFieldMap[key] = list;
  },
};

const actions = {
  getSize({ commit, state, rootState }, options) {
    const language = rootState.app.language;
    const UnitId = rootState.app.Unitid;
    const { valveName, serialId, serialName, params } = options;
    let type = "default";
    if (valveName === "Sov") {
      type = params.function;
    } else if (valveName === "Mv4") {
      type = params.variableSpeed || "All";
    } else if (valveName === "TxvOld") {
      type = params.Refrigerant + "-" + params.BalanceMet;
    }
    return new Promise((resolve, reject) => {
      //判断是否有动作方式
      if (
        state.serialSizeDic[language] &&
        state.serialSizeDic[language][UnitId] &&
        state.serialSizeDic[language][UnitId][serialName] &&
        state.serialSizeDic[language][UnitId][serialName][type]
      ) {
        const result = state.serialSizeDic[language][UnitId][serialName][type];
        console.log("get size result json string", result);
        resolve(JSON.parse(result));
      } else {
        getValve(params)
          .then((res) => {
            const { serialResult } = res.Data;
            if (serialResult.length > 0) {
              const serialInfo = serialResult.find(
                (info) => info.Id === serialId
              );
              const result = {
                SizeFields: serialInfo.SizeFields,
                SizeList: serialInfo.SizeList,
                CoilFields: serialInfo.CoilFields,
                CoilList: serialInfo.CoilList,
                CoreFields: serialInfo.CoreFields,
                CoreList: serialInfo.CoreList,
              };
              commit("SET_SERIAL_SIZE_DIC", {
                language,
                UnitId,
                serialName,
                type,
                result,
              });
              console.log("get size result api", result);
              resolve(result);
            } else {
              resolve({});
            }
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },
  async getSerialFields({ commit, state, rootState }, { ValveId, SerialId }) {
    const UnitId = rootState.app.Unitid;
    const key = `${ValveId}-${SerialId}-${UnitId}`;
    const fields = state.serialFieldMap[key];
    if (fields) return fields;
    const result = await getSerialField({
      valveId: ValveId,
      serialId: SerialId,
    });
    if (result.Result === 0) {
      const list = result.Data;
      commit("SET_SERIAL_FIELDS", { key, list });
      return list;
    }
    commit("SET_SERIAL_FIELDS", { key, list: [] });
    return [];
  },
  getSerialList({ commit, state, rootState }, valveName) {
    const { language, region } = rootState.app;
    const UnitId = rootState.app.Unitid;
    // console.log(rootState.app.language, language);
    const { valveSerialDic, valveDic } = state;
    return new Promise((resolve) => {
      console.log("vuex getSerialList", language, region);
      if (
        valveSerialDic[region] &&
        valveSerialDic[region][language] &&
        valveSerialDic[region][language][UnitId] &&
        valveSerialDic[region][language][UnitId][valveName]
      ) {
        resolve(valveSerialDic[region][language][UnitId][valveName]);
      } else {
        const valve = valveDic[valveName];
        getSerialList({ valveid: valve.Id }).then((res) => {
          console.log("vuex getSetialList", res);
          let { serialResult } = res.Data;
          serialResult.forEach((serial) => {
            const imageInfo = serial.ImgPath
              ? serial.ImgPath.split(";")
              : ["", ""];
            serial.imgUrl = ImgUrl + imageInfo[0];
            serial.pos = [];
            if (serial.PipelineA) {
              serial.pos.push("A");
            }
            if (serial.PipelineC) {
              serial.pos.push("C");
            }
            if (serial.PipelineD) {
              serial.pos.push("D");
            }
            return serial;
          });
          serialResult.sort((a, b) => a.Sort - b.Sort);
          commit("SET_VALVE_SERIAL_DIC", {
            valveName,
            language,
            UnitId,
            serialList: serialResult,
          });
          resolve(serialResult);
        });
      }
    });
  },
  getCondition({ commit, state }, options) {
    const language = Cookies.get("language");
    const UnitId = Cookies.get("UnitId") || 2;
    const { valveName, serialId } = options;
    return new Promise((resolve, reject) => {
      if (
        state.ConditionDic[language][`unit${UnitId}`][
          `${valveName}-${serialId}`
        ]
      ) {
        resolve(
          JSON.parse(
            state.ConditionDic[language][`unit${UnitId}`][
              `${valveName}-${serialId}`
            ]
          )
        );
      } else {
        getCondition(options)
          .then((res) => {
            commit("SET_CONDITION_DIC", {
              language,
              UnitId,
              valveName,
              serialId,
              result: res.Data,
            });
            resolve(res.Data);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },
  async getValveBaseInfo({ commit, dispatch }) {
    const res = await getBaseValve();
    const { valveInfo, valveSystem, systemSort } = res.Data;
    const { refrigerantList, countryList } = res.Data;
    let valveDic = {};
    let valvelist = valveInfo.filter((valve) => valve.Classname !== "Txv");
    valvelist.forEach((valve) => {
      const imgUrl = valve.Imgfilename
        ? valve.Imgfilename.split(";")
        : ["", ""];
      valve.imgUrl = ImgUrl + imgUrl[0];
      valve.valveName = valve.Classname;
      valve.pos = [];
      if (valve.PipelineA) {
        valve.pos.push("A");
      }
      if (valve.PipelineC) {
        valve.pos.push("C");
      }
      if (valve.PipelineD) {
        valve.pos.push("D");
      }
      valveDic[valve.Classname] = valve;
      valveDic[valve.Id] = valve;
    });
    let valveSystemDic = {};
    valveSystem.forEach((system) => {
      system.ValveIdList = system.Valveids
        ? system.Valveids.split(",").map(Number)
        : [];
      system.SerialIdList = system.Serialids
        ? system.Serialids.split(",").map(Number)
        : [];
      system.ImgUrl = ImgUrl + system.ImgUrl;
      valveSystemDic[system.Id] = system;
    });
    let valveSystemSort = systemSort.map((item) => {
      item.text = item.Message;
      return {
        ...item,
        value: item.Memo,
        label: item.Message,
      };
    });
    valvelist.sort((a, b) => a.Sort - b.Sort);
    const list = refrigerantList.map((item) => {
      return { label: item.Name, value: item.Name };
    });

    dispatch("app/setCountryList", countryList, { root: true });
    commit("SET_VALVE_BASE_INFO", valvelist);
    commit("SET_VALVE_DIC", valveDic);
    commit("SET_VALVE_SYSTEM", valveSystem);
    commit("SET_VALVE_SYSTEM_DIC", valveSystemDic);
    commit("SET_VALVE_SYSTEM_SORT", valveSystemSort);
    commit("SET_REFRIGERANT_LIST", list);
    return res;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
