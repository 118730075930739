import Cookies from "js-cookie";
import { UUID } from "@/utils";
import { Message } from "element-ui";
import { getLoginAuth } from "@/api/user";
import { getLanguage, loadedLanguages, loadLanguageAsync } from "@/lang/index";
import { saveUserSetting, saveUserCustomUnit } from "@/api/user";
import { getUserCustomUnit } from "@/api/user";
import { getWebConfig } from "@/api/valve";
const Prefix = process.env.VUE_APP_IMAGE_URL;
function getLang() {
  const language = getLanguage();
  if (!loadedLanguages.includes(language)) {
    loadLanguageAsync(language);
  }
  return language;
}

const UNIT_ID_KEY = "UnitId";
const NO_SHOW_AGAIN_KEY = "noShowAgain";
const CUSTOM_UNIT_ID_KEY = "customUnitId";

function getStorage(key) {
  const cookies = [UNIT_ID_KEY, NO_SHOW_AGAIN_KEY, CUSTOM_UNIT_ID_KEY];
  const value = localStorage.getItem(key);
  if (cookies.includes(key) && value) {
    Cookies.set(key, value);
  }
  return localStorage.getItem(key);
}

const state = {
  appInited: false,
  language: getLang(),
  SelectionShrink: false,
  hiddenSelection: false,
  Unitid: getStorage(UNIT_ID_KEY) || "2",
  langShow: false,
  country: "China",
  countryNationalFlag: "",
  countryList: [],
  countryMap: {},
  noCountry: false,
  countryName: null,
  region: "CHINA",
  customUnitId: "",
  customUnitGroupList: [],
  noticeShow: false,
  noticeType: "product", // product  survey
  noShowAgain: getStorage(NO_SHOW_AGAIN_KEY) || false,
  webConfigList: [],
  webConfigDic: {},
  simpleCode: getStorage("appSimple"),
  complexCode: getStorage("appComplex"),
};

const mutations = {
  SET_APP_INITED: (state, appInited) => {
    state.appInited = appInited;
  },
  SET_LANGUAGE: (state, language) => {
    state.language = language;
  },
  SET_COUNTRY_LIST: (state, list) => {
    state.countryList = list;
  },
  SET_COUNTRY_MAP: (state, obj) => {
    state.countryMap = obj;
  },
  SET_SELECTION_SHRINK: (state, shrink) => {
    state.SelectionShrink = shrink;
  },
  SET_HIDDEN_SELECTION: (state, hidden) => {
    state.hiddenSelection = hidden;
  },
  SET_LANG_SHOW: (state, show) => {
    state.langShow = show;
  },
  SET_COUNTRY: (state, country) => {
    state.country = country;
  },
  SET_REGION: (state, region) => {
    state.region = region;
  },
  SET_COUNTRY_NAME: (state, countryName) => {
    state.countryName = countryName;
  },
  SET_COUNTRY_NATIONAL_FLAG: (state, nationalFlag) => {
    state.countryNationalFlag = nationalFlag;
  },
  SET_CUSTOM_UNIT_ID: (state, customUnitId) => {
    Cookies.set(CUSTOM_UNIT_ID_KEY, customUnitId);
    localStorage.setItem(CUSTOM_UNIT_ID_KEY, customUnitId);
    state.customUnitId = customUnitId;
  },
  SET_NO_COUNTRY: (state, noCountry) => {
    state.noCountry = noCountry;
  },
  SET_CUSTOM_UNIT_GROUP_LIST: (state, unitGroupList) => {
    state.customUnitGroupList = unitGroupList;
  },
  SET_NOTICE_SHOW: (state, show) => {
    state.noticeShow = show;
  },
  SET_NOTICE_TYPE: (state, type) => {
    state.noticeType = type;
  },
  SET_NO_SHOW_AGAIN: (state, noShowAgain) => {
    Cookies.set(NO_SHOW_AGAIN_KEY, noShowAgain);
    localStorage.setItem(NO_SHOW_AGAIN_KEY, noShowAgain);
    state.noShowAgain = noShowAgain;
  },
  SET_UNIT_ID: (state, unitId) => {
    Cookies.set(UNIT_ID_KEY, unitId);
    localStorage.setItem(UNIT_ID_KEY, unitId);
    state.Unitid = unitId;
  },
  SET_WEB_CONFIG_LIST: (state, list) => {
    state.webConfigList = list;
  },
  SET_WEB_CONFIG_DIC: (state, dic) => {
    state.webConfigDic = dic;
  },
  SET_AUTH_CODE: (state, code) => {
    const { simpleCode, complexCode } = code;
    localStorage.setItem("appSimple", simpleCode);
    localStorage.setItem("appComplex", complexCode);
    state.simpleCode = simpleCode;
    state.complexCode = complexCode;
  },
};

const actions = {
  setAppInited({ commit }, appInited) {
    commit("SET_APP_INITED", appInited);
  },
  async setLanguage({ commit }, language) {
    await loadLanguageAsync(language);
    Cookies.set("language", language);
    localStorage.setItem("app-anguage", language);
    commit("SET_LANGUAGE", language);
  },
  setNoCountry({ commit }, noCountry) {
    commit("SET_NO_COUNTRY", noCountry);
  },
  setSelectionShrink({ commit }, shrink) {
    commit("SET_SELECTION_SHRINK", shrink);
  },
  setHiddenSelection({ commit }, hidden) {
    commit("SET_HIDDEN_SELECTION", hidden);
  },
  setLangShow({ commit }, show) {
    commit("SET_LANG_SHOW", show);
  },
  setCountry({ commit, state, dispatch }, country) {
    const { countryMap, noCountry, Unitid } = state;
    const countryInfo = countryMap[country];
    dispatch("setUnitId", Unitid || countryInfo.Unitid);
    commit("SET_COUNTRY", country);
    commit("SET_REGION", countryInfo.Location);
    commit("SET_COUNTRY_NAME", countryInfo.CountryEn);
    localStorage.setItem("curCountry", countryInfo.Id);
    if (noCountry) {
      console.log("set NoIpCountry");
      localStorage.setItem("NoIpCountry", countryInfo.Id);
    }
  },
  setCountryNationalFlag({ commit }, country) {
    commit("SET_COUNTRY_NATIONAL_FLAG", country);
  },
  setCustomUnitId({ commit, rootState }, CustomUnitId) {
    const token = rootState.user.token;
    if (token) {
      saveUserSetting({ CustomUnitId }).then((res) => {
        console.log("保存成功", res);
      });
    }
    commit("SET_CUSTOM_UNIT_ID", CustomUnitId);
  },
  addUnitGroup({ commit, state, rootState }, unitGroup) {
    const { customUnitGroupList } = state;
    const { token } = rootState.user;
    const item = {
      id: UUID(),
      unitName: unitGroup.unitName,
      value: unitGroup,
    };

    if (token) {
      let list = [];
      for (let key in unitGroup) {
        if (key !== "unitName") {
          const item = {
            name: unitGroup.unitName,
            unitType: key,
            unit: unitGroup[key],
          };
          if (unitGroup[key]) {
            list.push(item);
          }
        }
      }
      const params = { unitList: list };
      saveUserCustomUnit(params).then((res) => {
        if (res.Result === 0) {
          console.log("saveUserCustomUnit", res.Data);
          let customUnit = {};
          res.Data.forEach((unit) => {
            if (!customUnit.id) {
              customUnit.id = unit.Unitid;
              customUnit.unitName = unit.Name;
              customUnit.value = {};
            }
            customUnit.value[unit.UnitType] = unit.Unit;
          });
          customUnitGroupList.push(customUnit);
          commit("SET_CUSTOM_UNIT_GROUP_LIST", customUnitGroupList);
        } else {
          Message({
            message: res.ErrorMsg || "Error",
            type: "error",
          });
        }
      });
    } else {
      customUnitGroupList.push(item);
      commit("SET_CUSTOM_UNIT_GROUP_LIST", customUnitGroupList);
    }
  },
  getCustomUnitGroup({ commit }) {
    return new Promise((resolve) => {
      getUserCustomUnit().then((res) => {
        console.log(res);
        let unitIdlist = [];
        let customUnitList = [];
        let flag = 0;
        res.Data.forEach((unit) => {
          if (!unitIdlist.includes(unit.Unitid)) {
            unitIdlist.push(unit.Unitid);
            let item = {
              id: unit.Unitid,
              unitName: unit.Name,
              value: {},
            };
            item.value[unit.UnitType] = unit.Unit;
            customUnitList.push(item);
          } else {
            const unitIndex = customUnitList.findIndex(
              (unitGroup) => unitGroup.id === unit.Unitid
            );
            customUnitList[unitIndex].value[unit.UnitType] = unit.Unit;
          }

          flag++;
          if (flag === res.Data.length) {
            commit("SET_CUSTOM_UNIT_GROUP_LIST", customUnitList);
            resolve();
          }
        });
      });
    });
  },
  setNoticeShow({ commit }, show) {
    commit("SET_NOTICE_SHOW", show);
  },
  setNoticeType({ commit }, type) {
    commit("SET_NOTICE_TYPE", type);
  },
  setCountryList({ commit }, list) {
    const countrylist = list.filter((country) => {
      const label = `${country.Country} / ${country.CountryEn}`;
      country.label = label;
      return country.Country;
    });
    let countryMap = {};
    countrylist.forEach((country) => {
      countryMap[country.Id] = country;
    });

    commit("SET_COUNTRY_LIST", countrylist);
    commit("SET_COUNTRY_MAP", countryMap);
  },
  setNoticeNoShowAgain({ commit }, noShowAgain) {
    commit("SET_NO_SHOW_AGAIN", noShowAgain);
  },
  setUnitId({ commit, rootState }, unitId) {
    return new Promise((resolve) => {
      const token = rootState.user.token;
      if (token) {
        saveUserSetting({ unitId }).then((res) => {
          console.log("保存成功", res);
        });
      }
      commit("SET_UNIT_ID", unitId);
      resolve();
    });
  },
  getConfig({ commit }) {
    return new Promise((resolve) => {
      getWebConfig().then((res) => {
        console.log("web config", res.Data);
        const { list } = res.Data;
        const dic = {};
        list.forEach((webconfig) => {
          const { Type, Content, KeyName } = webconfig;
          if (Type === "Image") {
            webconfig.ImageUrl = Prefix + Content;
          }
          dic[KeyName] = webconfig;
        });
        commit("SET_WEB_CONFIG_LIST", list);
        commit("SET_WEB_CONFIG_DIC", dic);
        resolve();
      });
    });
  },
  async getLoginAuth({ commit, state }) {
    try {
      if (state.simpleCode && state.complexCode) {
        return true;
      }
      const resp = await getLoginAuth();
      if (resp.Result === 0) {
        commit("SET_AUTH_CODE", resp.Data);
      } else {
        return Promise.reject("getAuth Error");
      }
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
