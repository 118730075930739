import Vue from "vue";
import store from "@/store";
import Router from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import getPageTitle from "@/utils/get-page-title";
import { Message } from "element-ui";
import { getToken } from "@/utils/auth";
import home from "@/views/home/index";
Vue.use(Router);

import Layout from "@/views/layout";

const routes = [
  {
    path: "/home",
    name: "home",
    component: home,
  },
  {
    path: "/mobile",
    name: "mobile",
    component: () => import("@/views/mobile/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/login/register.vue"),
  },
  {
    path: "/reset",
    name: "reset",
    component: () => import("@/views/login/reset.vue"),
  },
  {
    path: "/changePhone",
    name: "changePhone",
    component: () => import("@/views/login/change.vue"),
    props: { resetInfo: "Phone" },
  },
  {
    path: "/changeEmail",
    name: "changeEmail",
    component: () => import("@/views/login/change.vue"),
    props: { resetInfo: "Email" },
  },
  {
    path: "/selector",
    redirect: "/selector/index",
    component: Layout,
    children: [
      {
        path: "/selector/index",
        component: () => import("@/views/selector/index"),
        name: "Selector",
        meta: { title: "", affix: true },
      },
    ],
  },
  {
    path: "/details",
    name: "details",
    component: () => import("@/views/selector/details"),
    props: (route) => ({
      PartNumber: route.query.PartNumber,
      Hashkey: route.query.Hashkey,
    }),
  },
  {
    path: "/sort",
    component: Layout,
    redirect: "/sort/index",
    children: [
      {
        path: "/sort/index",
        component: () => import("@/views/sort/index.vue"),
        name: "sort",
        meta: { title: "", affix: true },
      },
    ],
  },
  {
    path: "/export",
    name: "Export",
    component: () => import("@/views/export/index.vue"),
    props: (route) => ({ productList: route.query.productList }),
  },
];

const createRouter = () =>
  // eslint-disable-next-line no-undef
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: routes,
  });

const router = createRouter();

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  console.log(to, from);
  if (to.name === "login" && !to.query.redirect) {
    next("/login?redirect=" + from.name);
  }

  /*   const valveBaseInfo = store.getters.valveBaseInfo;
  if (valveBaseInfo.length == 0) {
    const language = store.getters.language;
    const nofirstload = localStorage.getItem("nofirstload");
    const res = await store.dispatch("valve/getValveBaseInfo");
    let { location, country, areaName, countryList } = res.Data;

    if (areaName === "NOCOUNTRY" && nofirstload !== "true") {
      store.dispatch("app/setLangShow", true);
      store.dispatch("app/setNoCountry", true);
    }
    if (areaName === "NOCOUNTRY" && nofirstload === "true") {
      const localCountry = localStorage.getItem("NoIpCountry");
      if (localCountry) {
        console.log("localCountry", localCountry);
        country = countryList.find((item) => item.Id == localCountry);
        console.log(country);
        location.Country = country.Country;
      }
    }
    console.log(nofirstload, location);
    if (country && country.Language) {
      localStorage.setItem("nofirstload", "true");
      const { Language, Unitid } = country;
      await store.dispatch("app/setLanguage", Language);
      await store.dispatch("app/setUnitId", Unitid);
      if (Language !== language) {
        await store.dispatch("valve/getValveBaseInfo");
      }
    }
    if (country && country.Id) {
      store.dispatch("app/setCountry", country.Id);
      store.dispatch("app/setCountryNationalFlag", country.NationalFlag);
    } else {
      if (location === "127.0.0.1") {
        store.dispatch("app/setCountry", "中国");
        store.dispatch("app/setCountryNationalFlag", "China");
      } else {
        let Country = {};
        const { CountryDic } = enums;
        for (let key in CountryDic) {
          const countryItem = CountryDic[key];
          if (countryItem.text === location.Country) {
            Country = countryItem;
          }
        }
        if (Country.name) {
          store.dispatch("app/setCountry", Country.text);
          store.dispatch("app/setCountryNationalFlag", Country.name);
        } else {
          store.dispatch("app/setCountry", location.Country);
          store.dispatch("app/setCountryNationalFlag", "");
        }
      }
    }
    await store.dispatch("recommend/getRecommend");
    await store.dispatch("app/getConfig");
  } */
  document.title = getPageTitle(to.meta.title);
  const hasToken = getToken();
  const AuthedPage = ["changePhone", "changeEmail"];
  if (AuthedPage.includes(to.name) && !hasToken) {
    next("/login?redirect=" + to.name);
  }
  if (hasToken) {
    const hasGetUserInfo = store.getters.name;
    const getSaveState = store.getters.getSaveState;
    if (!getSaveState) {
      try {
        await store.dispatch("selection/getSaveSelect");
      } catch (err) {
        await store.dispatch("selection/changeGetSaveState", false);
      }
    }
    if (!hasGetUserInfo) {
      try {
        store.dispatch("user/getInfo").then(() => {
          store.dispatch("app/getCustomUnitGroup");
        });
      } catch (error) {
        await store.dispatch("user/resetToken");
        Message.error(error || "Has Error");
        NProgress.done();
      }
    }
  }
  if (to.path === "/") {
    next("/home");
  } else next();
});
router.afterEach(() => {
  NProgress.done();
});

export default router;
